import React from 'react';
import { useState } from 'react';
import { InputLabel } from '@mui/material';

interface RatingProps {
  id?: string;
  value: number;
  updateValue: (newValue: number) => void;
}

export const Rating: React.FC<RatingProps> = ({ id, value, updateValue }) => {
  const [hoverAt, setHoverAt] = useState<number | null>(null);

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const oneClick = (rate: any) => {
	if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
        //console.log('double click');
        updateValue(0);
        setHoverAt(null);
    } else {
      const timeout = setTimeout(() => {
        //console.log('single click');
        updateValue(rate);
        setClickTimeout(null);
      }, 300); // 300ms 是标准的双击间隔
      setClickTimeout(timeout);
    }
  };

  return (
      <div style={{ cursor: 'pointer', fontSize: '18px' }}>
        {[0, 1, 2, 3, 4].map((i) => {
          const fullStars = hoverAt ?? value;
          return (
            <span
              onMouseOver={() => setHoverAt(i + 1)}
              onMouseOut={() => setHoverAt(null)}
              onClick={() => oneClick(i + 1)}
              key={`${id}_${i}`}
              style={{ color: 'red' }}
            >
              {i < fullStars ? '\u2605' : '\u2606'}
            </span>
          );
        })}
      </div>
  );
};
