import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import VDuration from './VSDuration';

const VDurationControl: React.FC<ControlProps> = ({ data, handleChange, path, uischema }) => {
  var new_data = data as any[];

  return (
    <VDuration 
      pds={new_data}
      setPds={(data) => handleChange(path, data)}
    />
  );
};


export default withJsonFormsControlProps(VDurationControl);
