import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/cate_elem_schema.json';
import uischema from './json/cate_elem_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  const debouncedSave = useCallback(
    _.debounce((newData) => {
      makeRequest('put', 'elem', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        console.log("ECateElem resp:", respData);
        if (response.code !== 200) {
            console.log("response:", response);
            setData(respData);
        }
        if(fcallback){
            fcallback(respData);
        }
      });
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Cate Elem";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    console.log("cate elem newData:", newData, " data:", data);
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

  return (
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ECondition;

