import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography, Autocomplete, List, ListItem, ListItemText, Paper, Collapse, Divider, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { AuthContext, makeRequest, addItem, getItem } from '../../AuthContext';

import { JsonForms } from '@jsonforms/react';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from '../customRenderers';

import * as cm from '../utils/common';

interface VAutoCompProps {
  data:any;
  onChange: (newData: any) => void;
}

  //"[{"OrderIdsString":","UnorderIdsString":"dbf74e1f-5879-4204-8440-d1aca8b2d0b8","OrderTodo":[],"UnorderTodo":[]},{"OrderIdsString":"","UnorderIdsString":"","OrderTodo":[],"UnorderTodo":[]},{"OrderIdsString":"","UnorderIdsString":"","OrderTodo":[],"UnorderTodo":[]}]""}}
const schema = {
    type: 'object',
    properties: {
        OrderIdsString: { type: 'string' },
        UnorderIdsString: { type: 'string' },
    },
};

const buildUiSchema = () => {
    const elements = ['OrderIdsString', 'UnorderIdsString'].map((property, index) => ({
        type: 'Control',
        scope: `#/properties/${property}`,
        format: 'ownAutoComplete',
        options: {
            oneMapName: property === 'OrderIdsString' ? 'Order Todos' : 'Unorder Todos',
            order: 1,
            autoType: 'todo',
            paraInt: index + 1,  // 将 paraInt 设置为索引 + 1
        },
    }));

    return {
        type: 'VerticalLayout',
        elements,
    };
};

const buildUiSchemas = () => {
    return Array.from({ length: 3 }, (_, index) => ({
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/OrderIdsString',
                format: 'ownAutoComplete',
                options: {
                    oneMapName: 'Order Todos',
                    order: 1,
                    autoType: 'todo',
                    paraInt: index + 1,  // paraInt 根据索引递增
                }
            },
            {
                type: 'Control',
                scope: '#/properties/UnorderIdsString',
                format: 'ownAutoComplete',
                options: {
                    oneMapName: 'Unorder Todos',
                    order: 1,
                    autoType: 'todo',
                    paraInt: index + 1,  // paraInt 根据索引递增
                }
            },
        ],
    }));
};

const uiSchemas = buildUiSchemas();

const VTodoGroup: React.FC<VAutoCompProps> = ({
    data, onChange
}) => {
    const [todoGroup, setTodoGroup] = useState<any[]>([]);

  useEffect(() => {
      let newTodoGroup:any[] = [];
      data.forEach((todo: any) => {
          newTodoGroup.push(todo);
      });
      console.log("newTodoGroup:", newTodoGroup);
      if (newTodoGroup.length >= 3 && newTodoGroup[0] && newTodoGroup[1] && newTodoGroup[2]) {
          newTodoGroup[0].Name = "Prepare";
          newTodoGroup[1].Name = "In Progress"; 
          newTodoGroup[2].Name = "Done";
          setTodoGroup(newTodoGroup);
      }
  }, []);

    const handleDataChange = (idx:number, newData: any) => {
        console.log("newData:", newData);
        let newTodoGroup:any[] = [...todoGroup];
        newTodoGroup[idx] = newData;
        setTodoGroup(newTodoGroup);
        if (newTodoGroup !== todoGroup) {
            console.log("newTodoGroup:", newTodoGroup);
            onChange(newTodoGroup);
        }
    }


    return (
        <Box>
            {todoGroup && todoGroup.map((todo: any, idx: number) => {
                return (
                    <Box key={idx}>
                    <Typography variant="h6" gutterBottom>
                        {todo.Name}
                    </Typography>
                    <JsonForms
                        key={idx}
                        schema={schema}
                        uischema={uiSchemas[idx]}
                        data={todo}
                        renderers={customRenderers}
                        cells={materialCells}
                        onChange={({ errors, data }) => handleDataChange(idx, data)}
                    />
                    </Box>
                )
            }
            )}
        </Box>
    )
};

export default VTodoGroup;
