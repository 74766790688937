import React from 'react';
import {Box, Typography} from '@mui/material';
import ReactMarkdown from 'react-markdown';

interface NoteContentProps {
  content: string;
}

const NoteContent: React.FC<NoteContentProps> = ({ content }) => {
  const isHTML = /^<\/?[a-z][\s\S]*>/i.test(content);

  const processedContent = content.replace(
 	 /<a\b([^>]*)>/g,
  	'<a$1 target="_blank" rel="noopener noreferrer">'
  );

  return (
    <Box
      sx={{
		"& img": {
		  maxWidth: "100%", // 限制图片宽度
		  height: "auto",   // 保持图片比例
		},
	  }}
    >
      {isHTML ? (
        <Typography
          variant="body2"
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: processedContent}}
        />
      ) : (
        <ReactMarkdown
          components={{
			a: ({ node, ...props }) => (
			  <a {...props} target="_blank" rel="noopener noreferrer" />
			),
		  }}
		>
		{content}
		</ReactMarkdown>
      )}
    </Box>
  );
};

export default NoteContent;

