import React from 'react';
import { AppBar, Toolbar, Grid, Typography, Box, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupIcon from '@mui/icons-material/Group';
import { AuthContext, makeRequest, addItem, getItem } from './AuthContext';
import { useState, useCallback, useEffect, useContext } from 'react';

import * as cm from './components/utils/common';


import TMood from './components/TMood';
import TPeople from './components/TPeople';
import TWeather from './components/TWeather';

interface HeaderPropos {
  editComp?: any;
  fcallback?: any;
}

const HeaderComponent: React.FC<HeaderPropos> = ({ editComp, fcallback }) => {

  const [condTemp, setCondTemp] = React.useState<any>();
  const [condGps, setCondGps] = React.useState<any>();

  const { formStack, setFormStack, userInfo, setUserInfo } = useContext(AuthContext)!;

  const [validTime, setValidTime] = React.useState<any>();
  const [validTimeStr, setvalidTimeStr] = React.useState<any>("");

  const jumpFormStack = (freeTime:any) => {
    setFormStack([...formStack, {type: "condAvail", uuid: freeTime.ID, data: freeTime, 
        fcallback: (newData: any) => {
            let newUserInfo = userInfo;
            console.log("FreeTime newData:", newData);
            setValidTime(newData);
            getItem(userInfo.FreeTime, cm.TYPE_RESOURCES, userInfo, (response: any) => {
                updateFreeTimeStr(response);
                if(fcallback) {
                    fcallback(response, "FreeTime");
                }
                localStorage.setItem("ValidTimeCond", JSON.stringify(response));
            });
        }
    }]);
  }

  //get free time str, like "16小时(周六/17)"
  //{"StartDate":"2024-11-01T22:00:00Z","AvailTime":57600000000000,"LeftAvailTime":57600000000000,"UpdateReminder":"2024-11-02T14:00:00Z"}
  const getFreeTimeStr = (freeTime: any) => {
    let start = new Date(freeTime.StartDate);
    let end = new Date(freeTime.UpdateReminder);
    let interval = freeTime.availTime; // in nanoseconds
    let now = new Date();
    let diff = end.getTime() - start.getTime();
    if (now.getTime() > start.getTime() && now.getTime() < end.getTime()) {
        diff = end.getTime() - now.getTime();
    }
    let hours = diff / 1000 / 60 / 60;
    //do not show minutes
    hours = Math.floor(hours);
    let week = start.getDay();
    let weekStr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    let day = start.getDate(); //with two number
    let dayStr = day < 10 ? "0" + day : day;
    return hours + "小时(" + weekStr[week] + "/" + dayStr + ")";
  }

  const updateFreeTimeStr = (CondValidTime: any) => {
      console.log("CondValidTime:", CondValidTime);
      if (CondValidTime) {
          let freeTimeStr = CondValidTime.ValueJson;
          let freeTime = JSON.parse(freeTimeStr);
          setvalidTimeStr(getFreeTimeStr(freeTime));
          return;
      }
      return;
      //setvalidTimeStr("16小时(周六/17)");
  }

  useEffect(() => {
        let lValidTimeCondStr = localStorage.getItem("ValidTimeCond");
        if (lValidTimeCondStr) {
            let lValidTimeCond = JSON.parse(lValidTimeCondStr);
            updateFreeTimeStr(lValidTimeCond);
        }
        getItem(userInfo.FreeTime, cm.TYPE_RESOURCES, userInfo, (response: any) => {
            console.log("cond response:", response);
            localStorage.setItem("ValidTimeCond", JSON.stringify(response));
            updateFreeTimeStr(response);
            if (response && response.AvailTimeId) {
                getItem(response.AvailTimeId, cm.TYPE_VALID_GROUP, userInfo, (availData: any) => {
                    setValidTime(availData);
                });
            }
        });
  }, []);

  const jumpToFreeTime = () => {
    if (!userInfo.FreeTime) {
        return;
    }

    if (validTime) {
        console.log("ValidTime:", validTime);
        jumpFormStack(validTime);
    }
  }

  return (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <TWeather editComp={userInfo.Weather} fcallback={fcallback} />
            {/*
            <Typography variant="body1">
              <span role="img" aria-label="cloud">☁️</span> 0°C~0°C <a href="#">南昌市</a>
            </Typography>
            */}
            <Typography 
                variant="body2"
                onClick={() => jumpToFreeTime()}
                style={{ cursor: 'pointer' }}
                >
                <span role="img" aria-label="music">🎶</span> 
                {validTimeStr}
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {userInfo.MoodConditon &&
            <Box>
              <TMood editComp={userInfo.MoodConditon} fcallback={fcallback} />
            </Box>
            }
            <TPeople editComp={userInfo.PeopleCate} fcallback={fcallback} />
          </Grid>
        </Grid>
  );
};

export default HeaderComponent;

