import { rankWith, isControl } from '@jsonforms/core';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';

import DateTimePickerControl from './renders/DateTimePickerControl';
import EnumControl from './renders/EnumControl';
import VDurationControl from './renders/VDurationControl';
import ReminderPickerControl from './renders/ReminderPickerControl';
import VAutoCompControl from './renders/VAutoCompControl';
import TimeDurationPickerControl from './renders/TimeDurationPickerControl';
import RatingControl from './utils/RatingControl';
import VTodoGroupControl from './renders/TodoGroupControl';
import NoteControl from './renders/NoteControl';

const isOwnNote = (uischema: any) => {
    return uischema.format === 'ownNote';
}

const isTodoGroup = (uischema: any) => {
    return uischema.format === 'ownTodoGroup';
}

const isRatingControl = (uischema: any) => {
    return uischema.format === 'ownRating';
}

const isTimeDurationPicker = (uischema: any) => {
    return uischema.format === 'timeDuration';
}

const isDateTimePicker = (uischema: any) => {
    return uischema.format === 'ownDate';
}

const isEnumControl = (uischema: any) => {
    return uischema.format === 'ownEnum';
}

const isVDurationControl = (uischema: any) => {
    return uischema.format === 'ownVDuration';
}

const isReminderControl = (uischema: any) => {
    return uischema.format === 'ownReminder';
}

const isAutoComplete = (uischema: any) => {
    return uischema.format === 'ownAutoComplete';
}


export const customRenderers = [
  ...materialRenderers,
  { tester: rankWith(4, isDateTimePicker), renderer: DateTimePickerControl},
  { tester: rankWith(4, isEnumControl), renderer: EnumControl},
  { tester: rankWith(4, isVDurationControl), renderer: VDurationControl},
  { tester: rankWith(4, isReminderControl), renderer: ReminderPickerControl},
  { tester: rankWith(4, isAutoComplete), renderer: VAutoCompControl},
  { tester: rankWith(4, isTimeDurationPicker), renderer: TimeDurationPickerControl},
  { tester: rankWith(4, isRatingControl), renderer: RatingControl},
  { tester: rankWith(4, isTodoGroup), renderer: VTodoGroupControl},
  { tester: rankWith(4, isOwnNote), renderer: NoteControl},
];
