import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import StackForm from './components/utils/StackForm';
import { TextField, Button, IconButton as MuiIconButton, Menu, MenuItem, Popover } from '@mui/material';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, CssBaseline, AppBar, Toolbar, Typography, Box, IconButton, Autocomplete } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import UserIcon from '@mui/icons-material/Person';
import { AuthContext, makeRequest } from './AuthContext';
import TodoItem from './components/TodoItem';
import EditTodo from './components/EditTodo';
import RFormStack from './components/RFormStack';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import VFastInput from './components/VFastInput';
import THeader from './THeader';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import * as cm from './components/utils/common';

const drawerWidth = 240;
const appbarHeight = 64;

const todoSubMenu = [
    {name: 'Start'},
    {name: 'Edit'},
    {name: 'Delete'},
    {name: 'Move to'},
]


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
/*
  backgroundColor: theme.palette.grey[200],
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
*/
  marginLeft: 0,
  width: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    width: '100%',
  },
}));


const App = () => {

  const { setJwt, formStack, setFormStack, globalData, setGlobalData } = useContext(AuthContext)!;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [listTodos, setListTodos] = useState<any[]>([]);
  const [todos, setTodos] = useState<any[]>([]);
  const [newItemName, setNewItemName] = useState('');
  const [newItemIcon, setNewItemIcon] = useState('');
  const [selectedItem, setSelectedItem] = useState("00000000-0000-0000-0000-000000000000");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const [touchTimer, setTouchTimer] = useState<NodeJS.Timeout | null>(null);
  const [searchMode, setSearchMode] = useState(false);
  const [editList, setEditList] = useState(false);

  const [isRecursive, setIsRecursive] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const handleLogout = () => {
    setJwt(null);
    setFormStack([]);
    navigate('/');
  };

  const handleSearchClick = () => {
    setSearchMode(!searchMode);
  };


  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleListItemClick = (uuid: string) => {
    // don't change the selected item if it's already selected or in 1 second after the last selection
    const lastSelectedDate = new Date(localStorage.getItem('lastSelectedDate') || 0);
    const currentDate = new Date();
    const diff = currentDate.getTime() - lastSelectedDate.getTime();
    if (selectedItem === uuid) {
        console.log('same item diff: ' + diff + 'ms');
        if (diff < 3000) {
          return;
        }
    }else{
        if (diff < 1000) {
          return;
        }
    }
    localStorage.setItem('lastSelectedDate', currentDate.toString());
    setSelectedItem(uuid);
    setFormStack([]);
    setShowSettings(false);
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

 const { userInfo } = React.useContext(AuthContext)!;

 useEffect(() => {
    if (userInfo !== null) {
        makeRequest('get', 'list', {
            ApiMethod:1,
            user_id: userInfo.ID
            }, {}, (data) => {
            const getLists = JSON.parse(data.data);
            listTodos.splice(0, listTodos.length);
            getLists.forEach((item: any) => {
                listTodos.push({ uuid: item.id, name: item.name, icon: <InboxIcon /> });
            });
            setListTodos([...listTodos]);
            listTodos.map(item => fetchTodos(item.uuid, (getTodos: any) => {
                if (item.uuid === selectedItem) {
                    console.log('update todos: ' + getTodos, "selectedItem: " + selectedItem);
                    setTodos(getTodos);
                }
                console.log('update list uuid: ' + item.uuid);
            }));
        });
    }
     let newGlobalData = globalData;
     newGlobalData = {Title: getCurrentDate()};
     setGlobalData(newGlobalData);
  }, []);

 //create 60s setInterval to update title
 const [titleInterval, setTitleInterval] = useState<NodeJS.Timeout | null>(null);
 useEffect(() => {
    if (titleInterval) {
        clearInterval(titleInterval);
    }
    setTitleInterval(setInterval(() => {
        if (formStack.length === 0) {
            let newGlobalData = globalData;
            newGlobalData = {Title: getCurrentDate()};
            setGlobalData(newGlobalData);
        }
    }, 60000));

    return () => {
        if (titleInterval) {
            clearInterval(titleInterval);
        }
    };
 }, []);

 const onChangeListName = (uuid: string, newName: string) => {
    let newItems = listTodos.map(item => item.uuid === uuid ? { ...item, name: newName } : item);
    setListTodos(newItems); 
 }

const getSubMenu = (item: any) => {
    if (cm.showCondValue(item) && formStack.length === 0){
        return todoSubMenu.concat({name: 'Resourse'}); 
    }
    return todoSubMenu;
}

 const updateListName = (e: any, uuid: string, newName: string) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action to stop scrolling when pressing Enter
        makeRequest('put', 'list', {
            id: uuid,
            name: newName
            }, {}, (data) => {
            const updatedList = JSON.parse(data.data);
            console.log('updated list: ' + updatedList);
            listTodos.map(item => item.uuid === updatedList.id ? { ...item, name: updatedList.name } : item);
            setListTodos([...listTodos]);
        });
    }
 }

/*
        let listItem = listTodos.find(item => item.uuid === listId);
        if(listItem && listItem.todos) {
            console.log('updateTodos: ' + listItem.todos);
            setTodos([...listItem.todos]);
        }
        */
 const fetchTodos = (seletedList: string, callback:any) => {
    if (userInfo !== null) {
        var apiMethod = 2; //by list, TBD:add All const var for tsx
        var listId = seletedList;
        if (seletedList === "00000000-0000-0000-0000-000000000000" || seletedList === "") {
          apiMethod = 4; //today
          listId = userInfo.DefaultList;
        }
        makeRequest('get', 'todo', {
            ApiMethod:apiMethod,
            ApiDays:1,
            ListID: listId,
            UserId: userInfo.ID
            }, {}, (data) => {
            const getTodos = JSON.parse(data.data);

            let newItems = listTodos.map(item => item.uuid === seletedList ? { ...item, todos: getTodos } : item);
            setListTodos(newItems);

            if(callback){
                callback(getTodos);
            }
        });
    }
 }

 const updateTodos = () => {
    if (userInfo !== null) {
        let listItem = listTodos.find(item => item.uuid === selectedItem);
        if(listItem && listItem.todos) {
            //console.log('updateTodos: ' + listItem.todos);
            setTodos([...listItem.todos]);
        }

        fetchTodos(selectedItem, (getTodos: any) => {
            let addTodos:any[] = [];
            getTodos.forEach((item: any) => {
                let group = cm.getGroupTodos(item)
                if (group.length > 0) {
                    for (let i = 0; i < group.length; i++) {
                        addTodos.push(group[i]);
                    }
                }
            });
            setTodos(addTodos);
            for (let i = 0; i < addTodos.length; i++) {
                let item = addTodos[i];
                if (cm.showCondValue(addTodos[i]) && formStack.length === 0 && 
                    (selectedItem === "00000000-0000-0000-0000-000000000000" || selectedItem === "")){
                    setIsRecursive(true);
                    setFormStack([...formStack, {type: 'recursive', uuid: addTodos[i].ID, data: addTodos[i].Recursive}]);
                    break;
                }
            }
        });
    }
 }

 const previousFormStack = useRef(formStack);
 useEffect(() => {
     //setSearchMode(false);
     if(formStack.length === 0  || (formStack.length > 0 && formStack[formStack.length - 1].type === 'recursive')) {
         return;
     }
     if (isRecursive) {
         setIsRecursive(false);
         return;
     }
    if (previousFormStack.current.length !== formStack.length) {
        console.log('formStack: ' + formStack.length + 'updateTodos');
        updateTodos();
    }
    previousFormStack.current = formStack;
  }, [formStack]);

 const preSelectedItem = useRef(selectedItem);
 useEffect(() => {
    if (selectedItem !== preSelectedItem.current) {
        console.log('selectedItem: ' + selectedItem + ' preSelectedItem: ' + preSelectedItem.current);
        updateTodos();
    }

    preSelectedItem.current = selectedItem;
    if (selectedItem === "00000000-0000-0000-0000-000000000000" || selectedItem === "") {
        setGlobalData({Title: getCurrentDate()});
    }else{
        setGlobalData({Title: listTodos.find(item => item.uuid === selectedItem)?.name});
    }
  }, [selectedItem]);


 const handleAddItem = () => {
    if (newItemName) {
        //console.log('add item');
        makeRequest('post', 'list', {
            user_id: userInfo.ID,
            name: newItemName
            }, {}, (data) => {
            const newItem = JSON.parse(data.data);
            listTodos.push({ uuid: newItem.id, name: newItem.name, icon: <InboxIcon /> });
            setListTodos([...listTodos]);
        });
        setNewItemName('');
        setNewItemIcon('');
    }
  }
  
  const handleDeleteItem = (uuid: string) => {
    setListTodos(listTodos.filter(item => item.uuid !== uuid));
    makeRequest('delete', 'list', {
        id: uuid,
        ApiMethod: 1
        }, {}, (data) => {
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to stop scrolling when pressing Enter
      handleAddItem();
    }
  };

  const handleInputBlur = () => {
    setSearchMode(false);
  };

  const handleSearchKeyDown = (newTodo: any) => {
    setTodos([...todos, newTodo]); // 更新父组件的todos
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setItemToDelete(null);
  };

  const AddItemForm = (
    <TextField
    //autoFocus
    margin="dense"
    id="name"
    label="New List"
    type="text"
    fullWidth
    variant="standard"
    value={newItemName}
    onChange={(e) => setNewItemName(e.target.value)}
    onKeyDown={handleKeyDown}
    />
  );

 const oneClickList = (event: any, uuid: string) => {
     if (!editList) {
        handleListItemClick(uuid);
    }
}

const DrawerList = (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
	<Toolbar sx={{ height: `${appbarHeight}px`, justifyContent: 'space-between' }}>
	  <IconButton
		color="inherit"
		aria-label="settings"
		edge="start"
		onClick={handleSettingsClick}
	  >
		<SettingsIcon />
	  </IconButton>

      {listTodos.length > 2 &&
	  <IconButton
		color="inherit"
		aria-label="edit"
		edge="end"
		onClick={() => setEditList(!editList)}
	  >
		<EditIcon />
	  </IconButton>
      }

	</Toolbar>


    {/* List 使用 flex-grow 占据剩余的空间 */}
    <List sx={{ flexGrow: 1 }}>
      {listTodos.map((item, i) => (
        <ListItem
          key={item.uuid}
          disablePadding
          onClick={(e) => oneClickList(e, item.uuid)}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!editList &&
              <ListItemButton selected={selectedItem === item.uuid}>
                <ListItemIcon>{item.icon} </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
          }
          {editList && i>1 &&
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        height: '60px',
                        padding: '8px',
                        }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={item.name}
                    onChange={(e) => onChangeListName(item.uuid, e.target.value)}
                    //onBlur={(e) => updateListName(e, item.uuid, item.name)}
                    onKeyDown={(e) => updateListName(e, item.uuid, item.name)}
                />
                <MuiIconButton
                    aria-label="delete"
                    onClick={() => handleDeleteItem(item.uuid)}
                >
                    <DeleteIcon />
                </MuiIconButton>
                </Box>
          }
        </ListItem>
      ))}
    </List>

    <Box sx={{ padding: 2 }}>{AddItemForm}</Box>
  </Box>
);

const settingsMenu = [
    {name: 'User', icon: <UserIcon />, onClick: () => setFormStack([...formStack, {type: 'user', uuid: userInfo.ID, data: userInfo}])},
    {name: 'Logout', icon: <LogoutIcon />, onClick: handleLogout},
]


  const SettingsList = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
	  {/* 顶部的工具栏 */}
	  <Toolbar sx={{ height: `${appbarHeight}px`, justifyContent: 'space-between' }}>
		<IconButton
		  color="inherit"
		  aria-label="close"
		  edge="start"
		  onClick={() => setShowSettings(!showSettings)}
		>
		  <SettingsIcon />
		</IconButton>
		<Box sx={{ flexGrow: 1 }} />
	  </Toolbar>

	  {/* 上面的项目列表，超出时滚动 */}
	  <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
		<List>
		  {settingsMenu.slice(0, -1).map((item) => (
			<ListItem
			  key={item.name}
			  disablePadding
			  onClick={item.onClick}
			>
			  <ListItemButton>
				<ListItemIcon>{item.icon} </ListItemIcon>
				<ListItemText primary={item.name} />
			  </ListItemButton>
			</ListItem>
		  ))}
		</List>
	  </Box>

	  {/* settingsMenu 中的最后一项，固定在底部 */}
	  <Box 
      sx={{ 
          borderTop: '0.1px solid #ccc',
          padding: '0px 0' 
          }}
      >
		<List>
		  {settingsMenu.slice(-1).map((item) => (
			<ListItem
			  key={item.name}
			  disablePadding
			  onClick={item.onClick}
			>
			  <ListItemButton>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText primary={item.name} />
			  </ListItemButton>
			</ListItem>
		  ))}
		</List>
	  </Box>
	</Box>
    
  );

  const getCurrentDate = () => {
    const daysOfWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const minute = String(today.getMinutes()).padStart(2, '0');
    return `Today(${dayOfWeek}/${day} ${hour}:${minute})`;
  };

  const changeTodoStatus = (todo: any) => {
    var newStatus = cm.TODO_STATUS_PRE_RUN;
    if (todo.Status === cm.TODO_STATUS_RUNNING) {
        newStatus = cm.TODO_STATUS_PRE_ONE_FINISH;
    }
    console.log('change status to: ' + cm.td_status_str[newStatus]);
    makeRequest('put', 'todo', {
        ID: todo.ID,
        UserID: userInfo.ID,
        Status: newStatus
        }, {}, (data) => {
            const updatedTodo = JSON.parse(data.data);
            console.log('updated todo: ' + cm.td_status_str[updatedTodo.Status]);
            let newTodos = todos.map(todo => todo.ID === updatedTodo.ID ? updatedTodo : todo)
            let listItem = listTodos.find(item => item.uuid === selectedItem);
            listItem.todos = newTodos;
            setTodos(newTodos);
            updateTodos();
            /*
            let upStatus = updatedTodo.Status;
            if (updatedTodo.TodoType !== 0  || upStatus === cm.TODO_STATUS_RUN_PREPARE || upStatus === cm.TODO_STATUS_RUN_SUB || upStatus === cm.TODO_STATUS_RUN_FINISH || cm.showCondValue(updatedTodo)){
                console.log('update todo type: ' + updatedTodo.TodoType);
                updateTodos();
            }
            */
    });
  }

  const handleMenuClick = (menuName: string, item: any) => {
    let subMenus = getSubMenu(item);
    console.log(`Menu clicked: ${menuName}`, item);
    for (let i = 0; i < subMenus.length; i++) {
        if (menuName === subMenus[i].name) {
            switch (menuName) {
                case 'Edit':
                    setFormStack([...formStack, {type: 'todo', uuid: item.ID, data: item}]);
                    break;
                case 'Start':
                    changeTodoStatus(item);
                    break;
                case 'Delete':
                    makeRequest('delete', 'todo', {
                        id: item.ID,
                        ApiMethod: 1
                        }, {}, (data) => {
                        setTodos(todos.filter(todo => todo.ID !== item.ID));
                    });
                    break;
                case 'Move to':
                    break;
                case 'Resourse':
                    console.log('Resourse setFormStack');
                    setIsRecursive(true);
                    setFormStack([...formStack, {type: 'recursive', uuid: item.ID, data: item.Recursive}]);
                    break;
                default:
                    break;
            }
        }
    }
  };

  const handleHeaderChange = (newData: any, htype:any) => {
      console.log('handleHeaderChange:', htype);
      if (htype === 'Mood') {
        updateTodos();
      }else if(htype === 'FreeTime') {
        updateTodos();
      }else if(htype === 'People') {
        updateTodos();
      }
  }

  const renderContentTodos = () => {
      // use formStack.length to determine the content
      if (formStack.length > 0) {
         return <RFormStack />
      }

      return ( 
           <Box sx={{ display: 'flex', flexDirection: 'column'}}>

      <Box sx={{ flexShrink: 0 }}>
        {(selectedItem === "00000000-0000-0000-0000-000000000000" || selectedItem === "") && 
            <THeader 
                fcallback={handleHeaderChange}
            />
     }
      </Box>

      {/* 可滚动的 todos 列表 */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List>
          {todos.map((item) => (
            <TodoItem key={item.ID} item={item} onMenuClick={handleMenuClick} subMenu={getSubMenu(item)} />
          ))}
        </List>
      </Box>
    </Box> 
       );
  }

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const oneClick = () => {
	if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
        setFormStack([]);
    } else {
      const timeout = setTimeout(() => {
        setFormStack(formStack.slice(0, formStack.length - 1));
        setClickTimeout(null);
      }, 300); // 300ms 是标准的双击间隔
      setClickTimeout(timeout);
    }

  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          height: { xs: `${appbarHeight}px`, sm: `${appbarHeight}px` },
          //bottom: isMobile ? 0 : 'auto', //TBD
          //top: isMobile ? 'auto' : 0,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: `${appbarHeight}px` }}>
            {
              formStack.length != 0 &&
              <IconButton
                color="inherit"
                aria-label="search"
                edge="end"
                onClick={oneClick}
                sx={{ mr: 2 }}
              >
               <ArrowBackIcon />
              </IconButton>
            }
            {
              formStack.length == 0 && !searchMode &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            }
        {searchMode && formStack.length === 0 ? 
         <VFastInput 
            selectedItem={selectedItem}
            userInfo={userInfo}
            onBlur={handleInputBlur}
            onSearch={handleSearchKeyDown}
         />
        :
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center', mr:2 }}>
            {globalData ? globalData.Title : "Today"}
          </Typography>
        }
        {formStack.length === 0 &&
          <IconButton
            color="inherit"
            aria-label="search"
            edge="end"
            onClick={handleSearchClick}
          >
            <SearchIcon />
          </IconButton>
        }
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: {md: "none",  xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {showSettings ? SettingsList : DrawerList}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {showSettings ? SettingsList : DrawerList}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          //overflowY: 'auto',  // 仅在内容溢出时显示滚动条
          //height: `calc(100vh - ${appbarHeight}px)`,  // 减去 AppBar 的高度
          //mt: `${appbarHeight}px`  // 使内容下移，避免被 AppBar 覆盖
        }}
      >
        <Toolbar sx={{ height: `${appbarHeight}px` }} />
        {renderContentTodos()}
      </Box>
    </Box>
  );
};

export default App;

