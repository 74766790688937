import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid } from '@mui/material';
import  { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

// 定义可用的时间单位和它们与纳秒的换算关系
const timeUnits = [
  { label: 'Weeks', value: 'weeks', multiplier: 7 * 24 * 60 * 60 * 1e9 },  // 1周 = 7天（转为纳秒）
  { label: 'Days', value: 'days', multiplier: 24 * 60 * 60 * 1e9 },        // 1天 = 24小时（转为纳秒）
  { label: 'Hours', value: 'hours', multiplier: 60 * 60 * 1e9 },           // 1小时 = 60分钟（转为纳秒）
  { label: 'Minutes', value: 'minutes', multiplier: 60 * 1e9 },            // 1分钟 = 60秒（转为纳秒）
];

// 将时间转换为相应单位下的数值
const convertToUnit = (duration: number, isSeconds: boolean) => {
  const baseDuration = isSeconds ? duration * 1e9 : duration; // 如果是秒，转换为纳秒
  for (const unit of timeUnits) {
    if (baseDuration % unit.multiplier === 0) {
      return { value: baseDuration / unit.multiplier, unit: unit.value };
    }
  }
  return { value: baseDuration, unit: 'minutes' };
};

// 将数值和单位转换为最终的时间（以纳秒或秒为单位）
const convertToDuration = (value: number, unit: string, isSeconds: boolean): number => {
  const selectedUnit = timeUnits.find((u) => u.value === unit);
  const durationInNanoseconds = value * (selectedUnit ? selectedUnit.multiplier : 1);
  return isSeconds ? durationInNanoseconds / 1e9 : durationInNanoseconds;
};

const TimeDurationPicker: React.FC<ControlProps> = ({
  data,
  handleChange,
  path,
  uischema,
}) => {
  const isSeconds = uischema?.options?.isSeconds ?? false; // 默认纳秒为单位
  const canNegative = uischema?.options?.canNegative ?? false; // 默认不允许负数
  const label = typeof uischema?.label === 'string' ? uischema.label : 'Duration';
  const [value, setValue] = useState<number>(0);
  const [unit, setUnit] = useState<string>('minutes');

  // 初始化时解析传入的数据
  useEffect(() => {
    if (data !== undefined) {
      const { value, unit } = convertToUnit(data, isSeconds);
      setValue(value);
      setUnit(unit);
    }
  }, [data, isSeconds]);

  const wrapperConvertToDuration = (value: number, unit: string) => {
      console.log('wrapperConvertToDuration', value, unit, "isSeconds", isSeconds, " canNegative", canNegative);
      if (!canNegative && value < 0) {
          setValue(0);
          return 0;
      }
      return convertToDuration(value, unit, isSeconds);
  };

  // 处理数值变化
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    setValue(newValue);
    handleChange(path, wrapperConvertToDuration(newValue, unit)); // 将转换后的时间传递回JsonForms
  };

  // 处理单位变化
  const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
    handleChange(path, wrapperConvertToDuration(value, newUnit)); // 更新时传递回JsonForms
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {/* 数值输入框 */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="number"
          label={label}
          variant="outlined"
          value={value}
          onChange={handleValueChange}
        />
      </Grid>

      {/* 单位选择框 */}
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          label="Unit"
          value={unit}
          onChange={handleUnitChange}
          variant="outlined"
        >
          {timeUnits.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

// 将自定义控件包装为JsonForms可用的组件
export default withJsonFormsControlProps(TimeDurationPicker);
