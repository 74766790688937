import React, { useState, useCallback, useEffect, useContext } from 'react';

import { JsonForms } from '@jsonforms/react';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';

import { Box, ListItem, ListItemText, IconButton, List, Divider, TextField, Typography  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import schema from './json/lcomposev_schema.json';
import uischema from './json/lcomposev_uischema.json';
import range_schema from './json/lrangev_schema.json';
import range_uischema from './json/lrangev_uischema.json';
import enum_schema from './json/lenumv_schema.json';
import enum_uischema from './json/lenumv_uischema.json';
import gps_schema from './json/lgpsv_schema.json';
import gps_uischema from './json/lgpsv_uischema.json';

import _ from 'lodash';
import { updateAllEnumValue, reverseAllEnumValue  } from './utils/common';
import { AuthContext, makeRequest } from '../AuthContext';

const gname_schema = {
    type: 'Control',
    scope: '#/properties/MapName',
}

const insertNameToFirstElement = (rawdata: any) => {
    let data = {...rawdata};
    let raw_elements = data.elements;
    raw_elements.unshift(gname_schema);
    data.elements = raw_elements;
    return data;
}

const mergeNameToFirstElement = (rawdata: any) => {
    let data: any[] = [
        gname_schema,
        rawdata
    ]
    return data;
}

const schema_mapbase = {
    type: 'object',
    properties: {
        MapCRange: {
            title: 'Range',
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    MapName: { type: 'string' },
                    ...range_schema.properties
                }
            }
        },
    },
}

const uischema_mapbase = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/MapCRange',
            options: {
                showSortButtons: false,
                detail: () => insertNameToFirstElement(range_uischema)
            }
        }
    ]
}



const schema_map_enum = {
    type: 'object',
    properties: {
        MapCEnum: {
            title: 'Enum',
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    MapName: { type: 'string' },
                    ...enum_schema.properties,
                }
            }
        },
    },
}

const uischema_map_enum = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/MapCEnum',
            options: {
                showSortButtons: false,
                detail: {
                    type: 'VerticalLayout',
                    elements: [{}]
                }
            }
        }
    ]
}

const schema_map_gps = {
    type: 'object',
    properties: {
        MapCGps: {
            title: 'GPS',
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    MapName: { type: 'string' },
                    ...gps_schema.properties
                }
            }
        },
    },
}

const uischema_map_gps = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/MapCGps',
            options: {
                showSortButtons: false,
                detail: () => insertNameToFirstElement(gps_uischema)
            }
        }
    ]
}

const map_to_array = (map: any, isEnum:boolean = false) => {
    let newMap = [];
    for (let key in map) {
        let item = map[key];
        if (isEnum) {
            item = updateAllEnumValue(item);
        }
        let newItem = {MapName: key, ...item};
        newMap.push(newItem);
        if (isEnum) {
            console.log("newMap For Enum:", newMap);
        }
    }
    return newMap;
}


const updateMap = (composeData: any) => {
    let newComposeData = {...composeData};
    newComposeData.MapCRange = map_to_array(composeData.CRange);
    newComposeData.MapCGps = map_to_array(composeData.CGps);
    newComposeData.MapSubComposeR = map_to_array(composeData.SubComposeR);
    newComposeData.MapCEnum = map_to_array(composeData.CEnum, true);
    return newComposeData;
}

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
  recursive?: number;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback, recursive = 1 }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;
  const [inputValue, setInputValue] = useState('');
  	

  const debouncedSave = useCallback(
    _.debounce((newData) => {
        if(fcallback){
            fcallback(newData);
        }
    }, 5000),
    []
  );

  useEffect(() => {
    let updateMapData = updateMap(editComp);
    console.log("ECompose editComp:", updateMapData);
    uischema_mapbase.elements[0].options.detail = insertNameToFirstElement(range_uischema);
    uischema_map_enum.elements[0].options.detail = {
        type: 'VerticalLayout',
        elements: mergeNameToFirstElement(enum_uischema.elements[0])
    }
    uischema_map_gps.elements[0].options.detail = insertNameToFirstElement(gps_uischema);
    setData(updateMapData);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    if(newData !== data) {
        setData(newData);
        debouncedSave(newData);
    }
  }

  const handleMainChange = (newData: any, replace: string) => { 
    let upDate  = {...newData};
    let mapitems = upDate['Map'+replace];
    if (mapitems === undefined || mapitems === data['Map'+replace]) {
        return;
    }
    let newObject = mapitems.reduce((acc: any, item: any) => {      
        acc[item.MapName] = item
        if (replace === "CEnum") {
            acc[item.MapName] = reverseAllEnumValue(acc[item.MapName]);
        }
        return acc;                                       
    }, {});
    upDate[replace] = newObject;
    if (replace === "CEnum") {
        console.log("upDate For Enum:", upDate);
    }
    if (upDate !== data) {
        console.log("new upDate:", upDate);
        setData(upDate);
        debouncedSave(upDate);
    }
 }

 const { setJwt, formStack, setFormStack, userInfo } = useContext(AuthContext)!;
 const navigateOrder = (item: any) => {
    setFormStack([...formStack, {type: "SubComposeR", uuid: item.Name, data: item, recursive: recursive - 1, 
        fcallback: (newData: any) => {
            let newSubComposeR = {...data.SubComposeR};
            newSubComposeR[item.Name] = newData;
            let updata = {...data, SubComposeR: newSubComposeR};
            setData(updata);
            debouncedSave(updata);
        }
    }]);
 }

 const deleteListItem = (key: any) => {
     let newSubComposeR = {...data.SubComposeR};
     newSubComposeR = _.omit(newSubComposeR, key);
     let newData = {...data, SubComposeR: newSubComposeR}
     setData(newData);
     debouncedSave(newData);
 }

 const addSubComposeR = (key:string) => {
    let newSubComposeR = {...data.SubComposeR};
    newSubComposeR[key] = {Name: key};
    let newData = {...data, SubComposeR: newSubComposeR};
    setData(newData);
    debouncedSave(newData);
 }

 const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
 }

 const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
        console.log("Enter Key Pressed:", inputValue);
        addSubComposeR(inputValue);
        setInputValue('');
    }
 }

 
 const SubComposeRUI = () => {
    let subComposeR = data.SubComposeR;
    let subComposeRItems = Object.keys(subComposeR || {});
    console.log("subComposeRItems:", subComposeRItems);
    return (
        <Box marginTop={2}>
           <Typography gutterBottom>
           SubComposeR
           </Typography>
           {subComposeRItems.length > 0 && 
           <List
			  key={subComposeRItems.length + 1}
			  style={{
				border: '1px solid #ccc',
				borderRadius: '5px',
				padding: '10px',
				marginBottom: '10px',
				overflowY: 'auto',
				maxHeight: '150px',
			  }}
			>
			  {subComposeRItems.map(key => {
				const item = subComposeR[key];
				return (
				  <div key={key}>
					<ListItem
					  style={{
						cursor: 'pointer',
						color: 'link',
					  }}
					  secondaryAction={
						<IconButton edge="end" aria-label="delete" onClick={() => deleteListItem(key)}>
						  <DeleteIcon />
						</IconButton>
					  }
					>
					  <ListItemText
						onClick={() => navigateOrder(item)}
						primary={key}
					  />
					</ListItem>
					<Divider />
				  </div>
				);
			  })}
		  </List>
           }
		  <TextField
			  fullWidth
			  label="Add SubComposeR"
			  variant="outlined"
			  value={inputValue}
			  onChange={handleInputChange}  // 用于处理输入更改
			  onKeyDown={handleKeyPress}    // 用于处理按键事件，例如 Enter 键
			/>
        </Box>
    )
 };


  return (
  <Box>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
        <JsonForms
          schema={schema_mapbase}
          uischema={uischema_mapbase}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleMainChange(data, "CRange")}
        />
        <JsonForms
          schema={schema_map_enum}
          uischema={uischema_map_enum}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleMainChange(data, "CEnum")}
        />
        <JsonForms
          schema={schema_map_gps}
          uischema={uischema_map_gps}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleMainChange(data, "CGps")}
        />
        {recursive > 0 && SubComposeRUI()}

  </Box>
        
  );
};

export default ECondition;
