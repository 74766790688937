import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { withJsonFormsControlProps } from '@jsonforms/react';


const EnumControl: React.FC<ControlProps> = ({ data, handleChange, path, uischema}) => {
  const value = data !== undefined ? data : '';
  const label = typeof uischema.label === 'string' ? uischema.label : 'Select';
  const enumOptions = uischema.options ? uischema.options.enumOptions : [];

 const enumValues = uischema.options && uischema.options.enumValues
  ? uischema.options.enumValues.map((value: any) => Number(value))
  : enumOptions.map((option: any, index: number) => index);
 const multi = uischema.options && uischema.options.multi;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={multi}
        value={value}
        onChange={(event) => handleChange(path, multi ? event.target.value.map(Number) : Number(event.target.value))}
        label={label}
      >
      {enumOptions.map((option: any, index: number) => (
        <MenuItem key={index} value={enumValues[index]}>
          {option}
        </MenuItem>
      ))}
      </Select>
    </FormControl>
  );
};

export default withJsonFormsControlProps(EnumControl);
