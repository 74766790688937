import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, Typography, Stack, Paper, Grid, Box, Divider } from '@mui/material';
import VBSelectNumLimit  from './VBSelectNum';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const durationBaseType = [
  'WD_YEAR',
  'WD_MONTH',
  'WD_WEEK',
  'WD_DAY',
  'WD_HOUR',
  'WD_MINUTE',
  'WD_SECOND',
  'W_NONE',
];

const UnitMaxIdx = 7;

interface PureArray {
  SubCount?: number;
  SubIndex: number;
}

interface VDurationProps {
  pds: any[];
  setPds: (pds: any[]) => void;
}

const VDuration: React.FC<VDurationProps> = ({ pds, setPds }) => {
  const [pdsEdit, setPdsEdit] = useState<any[]>(pds);
  const [baseIdx, setBaseIdx] = useState<number>(UnitMaxIdx);
  const [subUnitIdx, setSubUnitIdx] = useState<boolean[]>(Array(UnitMaxIdx).fill(false));
  const [pInners, setPInners] = useState<PureArray[][]>(Array(UnitMaxIdx).fill([]));
  const [totalValue, setTotalValue] = useState<string>('0');

  useEffect(() => {
    updateValue();
  }, []);

  const handleTotalChange = (idx: number, value: string) => {
    if (idx >= 0 && idx < UnitMaxIdx) {
      const curValue = parseInt(value, 10) || 0;
      setTotalValue(value);
      if ((pdsEdit[idx]?.PureTotal || 0) !== curValue) {
        setSubUnitIdx((prev) => prev.map((_, i) => i === idx));
        setPdsEdit((prev) =>
          prev.map((item, i) => (i === idx ? { ...item, PureTotal: curValue } : { ...item, PureTotal: 0 }))
        );
        setPInners((prev) => prev.map((_, i) => (i === idx && curValue === 1 ? [{ SubCount: 1, SubIndex: 0 }] : [])));
      }
    }
  };

  const handleBaseUnitChange = (value: number) => {
    if (baseIdx !== value) {
      setBaseIdx(value);
      setSubUnitIdx((prev) => prev.map((_, i) => i === value));
      setPInners((prev) => prev.map(() => []));
    }
  };

  const handleSubUnitChange = (idx: number, value: number) => {
    setSubUnitIdx((prev) =>
      prev.map((_, i) => (i === value ? true : i > idx ? false : prev[i]))
    );
    setPInners((prev) => prev.map((_, i) => (i === value ? [] : prev[i])));
  };

  const handleGroupIntChange = (idx: number, value: number[]) => {
    setPInners((prev) =>
      prev.map((inner, i) =>
        i === idx ? value.map((v) => ({ SubCount: v, SubIndex: 0 })) : inner
      )
    );
  };

  const getMinUnit = (limitPre: number) => {
    switch (limitPre) {
      case 0:
      case 1:
      case 2:
        return 3; // Day
      case 3:
        return 4; // Hour
      case 4:
        return 5; // Minute
      case 5:
        return 6; // Second
      default:
        return limitPre + 1;
    }
  };

  const saveDuration = () => {
      let newPdsEdit = pdsEdit;
      for (let i = 0; i < baseIdx; i++) {
          newPdsEdit[i].PureTotal = 0;
          newPdsEdit[i].PureArrays = [];
      }
      for (let idx = baseIdx; idx < pdsEdit.length; idx++) {
          if (subUnitIdx[idx] || idx === baseIdx) {
              //filter out SubCount != 0
              let newPInners = pInners[idx]
              let pInnersFiltered = newPInners.filter((inner) => inner.SubCount !== 0)
              newPdsEdit[idx].PureArrays = [pInnersFiltered];
          } else {
              newPdsEdit[idx].PureArrays = [];
          }
          if (idx !== baseIdx && (pds[idx].PureArrays || []).length === 0 && pInners[idx].length === 0) {
              newPdsEdit[idx].PureTotal = 0;
          }
      }
      setPdsEdit(newPdsEdit);
      setPds(newPdsEdit);
  };

  const showCurrentArray = (idx: number) => {
    return idx === baseIdx || (idx > baseIdx && subUnitIdx[idx]);
  };

  const updateValue = () => {
    let changed = false;
    let newBaseIdx = baseIdx;
    let newTotalValue = totalValue;
    for (let i = 0; i < pdsEdit.length; i++) {
        if (!pdsEdit[i]?.PureTotal) {
            pdsEdit[i].PureTotal = 0;
        }
        if (!pdsEdit[i]?.PureArrays?.length) {
            pdsEdit[i].PureArrays = [[]];
        }
        if (pdsEdit[i].PureTotal > 0) {
            newBaseIdx = i;
            newTotalValue = pdsEdit[i].PureTotal;
            break;
        }
    }
    setBaseIdx(newBaseIdx);
    setTotalValue(newTotalValue.toString());
    let newSubUnitIdx = subUnitIdx;
    for (let i = 0; i < pdsEdit.length; i++) {
        newSubUnitIdx[i] = i === newBaseIdx;
        let curPds = pdsEdit[i];
        if (curPds.PureArrays && curPds.PureArrays.length > 0) {
            if (curPds.PureArrays[0] && curPds.PureArrays[0].length > 0) {
                newSubUnitIdx[i] = true;
            }
        }
    }
    setSubUnitIdx(newSubUnitIdx);
    setPInners(pdsEdit.map((d) => (d.PureArrays || []).flat()));
    console.log("updateValue", pdsEdit, newBaseIdx, newTotalValue, newSubUnitIdx);
  };

  const getSubUnitIdx = (idx: number) => {
      for (let i = idx+1; i < UnitMaxIdx; i++) {
          if (subUnitIdx[i]) {
              return i;
          }
      }
      return UnitMaxIdx;
  }

  const getSubUnitBaseIdx = (idx: number) => {
      if(idx === 0) { //WD_YEAR
          return [1, 2, 3, 7]; //WD_MONTH, WD_WEEK, WD_DAY, W_NONE
      }else if (idx === 1) {  //WD_MONTH
          return [2, 3, 7];    //WD_WEEK, WD_DAY, W_NONE
      }else if (idx === 2) {  //WD_WEEK
        return [3, 7];   //WD_DAY, W_NONE
      } else if (idx === 3) { //WD_DAY
        return [4, 7];    //WD_HOUR, W_NONE
        } else if (idx === 4) { //WD_HOUR
        return [5, 7];    //WD_MINUTE, W_NONE
        } else if (idx === 5) { //WD_MINUTE
        return [6, 7];    //WD_SECOND, W_NONE
        }
        return [7]; //W_NONE
  }

  const getSubUnitOptions = (idx: number) => {
      let options = [];
      let units = getSubUnitBaseIdx(idx);
      for (let i = 0; i < units.length; i++) {
          options.push(
            <MenuItem key={units[i]} value={units[i]}>
                {durationBaseType[units[i]]}
            </MenuItem>
          );
      }
      return options;
  }

//<Accordion defaultExpanded>
  return (
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>WD_CUSTOME_DURATION</Typography>
        </AccordionSummary>
        <AccordionDetails>
  <Box sx={{ padding: 2 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          label="WD_Freq"
          type="number"
          value={totalValue}
          onChange={(e) => handleTotalChange(baseIdx, e.target.value)}
          inputProps={{ 
              min: 1,
              step: 5
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          label="Select Unit"
          value={baseIdx}
          onChange={(e) => handleBaseUnitChange(parseInt(e.target.value, 10))}
          fullWidth
        >
          {durationBaseType.map((type, i) => (
            <MenuItem key={i} value={i}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>

    {pdsEdit.length > baseIdx && pdsEdit[baseIdx]?.PureTotal > 0 &&
      subUnitIdx.map((_, idx) =>
        showCurrentArray(idx) ? (
          <Box key={idx} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                select
                label={durationBaseType[idx] + " SubUnit"}
                value={getSubUnitIdx(idx)}
                onChange={(e) => handleSubUnitChange(idx, parseInt(e.target.value, 10))}
                sx={{ 
					minWidth: 150 ,
				}}
              >
                {getSubUnitOptions(idx)}
              </TextField>
            </Stack>
            <Box 
              sx = {{
                  mt: 2,
              }}
            >
            <VBSelectNumLimit
              curIdx={idx}
              baseIdxUnit={baseIdx}
              setBaseIdxUnit={setBaseIdx}
              pds_edit={pdsEdit}
              setPdsEdit={setPdsEdit}
              subUnitIdx={subUnitIdx}
              setSubUnitIdx={setSubUnitIdx}
              selection={pInners[idx].map((inner) => inner.SubCount || 0)}
              setSelection={(value) => handleGroupIntChange(idx, value)}
              //sx={{ mt: 1 }} // Add margin for spacing
            />
            </Box>
          </Box>
        ) : null
      )}
    <Box sx={{ mt: 3 }}>
      <Button variant="contained" color="primary" onClick={saveDuration}>
        {'Save'}
      </Button>
    </Box>
  </Box>
        </AccordionDetails>
</Accordion>
 
  );
};

export default VDuration;

