import React, { useEffect, useState } from 'react';
import { TextField, Box, Button, Grid, Typography } from '@mui/material';

interface VSelectByInputProps {
  baseIdx?: number;
  valueMin?: number;
  valueMax?: number;
  rowMax?: number;
  labelString?: string;
  selection: number[];
  setSelection: (selection: number[]) => void;
}

const VSelectByInput: React.FC<VSelectByInputProps> = ({
  baseIdx = 1,
  valueMin = -5,
  valueMax = 100,
  rowMax = 10,
  labelString = "W_INPUT",
  selection,
  setSelection,
}) => {
  const [curInput, setCurInput] = useState<string>('1');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurInput(e.target.value);
  };

  const handleCommit = () => {
    const addValue = parseInt(curInput, 10);
    if (!isNaN(addValue) && addValue <= valueMax && addValue >= valueMin) {
      if (!selection.includes(addValue)) {
        if (addValue !== 0 || baseIdx !== 1) {
          const newSelection = [...selection, addValue].sort((a, b) => a - b);
          setSelection(newSelection);
        }
      }
    }
  };

  const handleRemove = (curValue: number) => {
    const updatedSelection = selection.filter(value => value !== curValue).sort((a, b) => a - b);
    setSelection(updatedSelection);
  };

  const colMax = Math.ceil(selection.length / rowMax);
  const colWidth = 100 / rowMax; // Adjust the width based on rowMax


  const handleSearchKeyDown = (e: any) => {
    const event = e as React.KeyboardEvent<HTMLDivElement>;
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to stop scrolling when pressing Enter
      handleCommit();
    }
  }


  return (
    <Box>
      <Box display="flex" alignItems="center">
        <TextField
          value={curInput}
          type="number"
          label={labelString}
          onChange={handleChange}
          onKeyDown={handleSearchKeyDown}
          inputProps={{ 
              min: 1,
              step: 5,
              style: { textAlign: 'center' }
              }}
        />
      </Box>

      <Grid container spacing={1}>
        {[...Array(colMax)].map((_, col) => (
          <Grid container item key={col} spacing={1}>
            {[...Array(rowMax)].map((_, idx) => {
              const selIdx = col * rowMax + idx;
              const curValue = selection[selIdx];
              if (curValue !== undefined) {
                return (
                  <Grid item key={idx} xs={colWidth}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => handleRemove(curValue)}
                    >
                      {curValue}
                    </Button>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

interface VSelectByTapProps {
  baseIdx?: number;
  valueMin?: number;
  valueMax?: number;
  rowMax?: number;
  selection: number[];
  setSelection: (selection: number[]) => void;
}

const VSelectByTap: React.FC<VSelectByTapProps> = ({
  baseIdx = 1,
  valueMin = -5,
  valueMax = 100,
  rowMax = 10,
  selection,
  setSelection,
}) => {
  const colMax = Math.ceil((valueMax - valueMin + 1) / rowMax);

  const handleToggle = (curValue: number) => {
    const isSelected = selection.includes(curValue);
    const updatedSelection = isSelected
      ? selection.filter(value => value !== curValue)
      : [...selection, curValue].sort((a, b) => a - b);
    setSelection(updatedSelection);
  };

  useEffect(() => {
      console.log("rowMax1:", rowMax, "ColMax:", colMax);
  }, []);

  return (
    <Grid container spacing={1}>
      {[...Array(colMax)].map((_, col) => (
        <Grid container item key={col} spacing={1}>
          {[...Array(rowMax)].map((_, idx) => {
            const curValue = valueMin + col * rowMax + idx;
            if (curValue <= valueMax && (curValue !== 0 || baseIdx !== 1)) {
              const isSelected = selection.includes(curValue);
              return (
                <Grid item key={idx}>
                  <Button
                    fullWidth
                    variant={isSelected ? 'contained' : 'outlined'}
                    onClick={() => handleToggle(curValue)}
                  >
                    {curValue}
                  </Button>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      ))}
    </Grid>
  );
};

interface VBSelectNumProps {
  baseIdx?: number;
  valueMin?: number;
  valueMax?: number;
  rowMax?: number;
  selection: number[];
  setSelection: (selection: number[]) => void;
}

const VBSelectNum: React.FC<VBSelectNumProps> = ({
  baseIdx = 1,
  valueMin = -5,
  valueMax = 100,
  rowMax = 10,
  selection,
  setSelection,
}) => {
  const maxNum = baseIdx > 0 ? valueMax - valueMin : valueMax - valueMin + 1;

  return maxNum <= 60 ? (
    <VSelectByTap
      baseIdx={baseIdx}
      valueMin={valueMin}
      valueMax={valueMax}
      rowMax={rowMax}
      selection={selection}
      setSelection={setSelection}
    />
  ) : (
    <VSelectByInput
      baseIdx={baseIdx}
      valueMin={valueMin}
      valueMax={valueMax}
      rowMax={rowMax}
      selection={selection}
      setSelection={setSelection}
    />
  );
};

interface VBSelectNumLimitProps {
  curIdx: number;
  baseIdxUnit: number;
  setBaseIdxUnit: (baseIdxUnit: number) => void;
  pds_edit: any[]; // PureDuration
  setPdsEdit: (pds_edit: any[]) => void;
  subUnitIdx: boolean[];
  setSubUnitIdx: (subUnitIdx: boolean[]) => void;
  selection: number[];
  setSelection: (selection: number[]) => void;
}

const VBSelectNumLimit: React.FC<VBSelectNumLimitProps> = ({
  curIdx,
  baseIdxUnit,
  setBaseIdxUnit,
  pds_edit,
  setPdsEdit,
  subUnitIdx,
  setSubUnitIdx,
  selection,
  setSelection,
}) => {
  useEffect(() => {
    // Automatically update pds_edit when subUnitIdx[curIdx] is true
    //console.log("curIdx:", curIdx, "subUnitIdx:", subUnitIdx[curIdx], "baseIdxUnit:", baseIdxUnit, "pds_edit:", pds_edit);
    if (subUnitIdx[curIdx]) {
      const limit = getLimitMax(baseIdxUnit, curIdx);
      if (limit[1] > limit[0]) {
        const updatedPdsEdit = [...pds_edit];
        updatedPdsEdit[curIdx].PureTotal = limit[1];
        setPdsEdit(updatedPdsEdit);
      }
    }
  }, []);
  //}, [curIdx, subUnitIdx, baseIdxUnit, pds_edit, setPdsEdit]);

  const getLimitMax = (base: number, limitCur: number): [number, number, number, number] => {
    let limitPre = base;
    
    // Find the closest previous subUnitIdx
    if (base <= limitCur) {
      for (let i = limitCur - 1; i >= base; i--) {
        if (subUnitIdx[i]) {
          limitPre = i;
          break;
        }
      }
    }

    // Return corresponding limits based on the limitPre and limitCur
    if (limitPre === 0) {
      if (limitCur === 1) return [1, 12, 1, 6];
      if (limitCur === 2) return [-1, 52, 1, 10];
      if (limitCur === 3) return [-1, 365, 1, 10];
    } else if (limitPre === 1) {
      if (limitCur === 2) return [-1, 4, 1, 6];
      if (limitCur === 3) return [-3, 28, 1, 7];
    } else if (limitPre === 2) {
      if (limitCur === 3) return [1, 7, 1, 7];
    } else if (limitPre === 3) {
      if (limitCur === 4) return [0, 23, 0, 8];
    } else if (limitPre === 4) {
      if (limitCur === 5) return [0, 59, 0, 10];
    } else if (limitPre === 5) {
      if (limitCur === 6) return [0, 59, 0, 10];
    }

    // Handle the case when limitPre equals limitCur and it's the baseIdxUnit
    if (limitPre === limitCur && limitPre === baseIdxUnit) {
      const curTotal = pds_edit[base]?.PureTotal;
      if (curTotal !== undefined && curTotal >= 0) {
        if (curTotal >= 10) {
          return [1, curTotal, 1, 10];
        } else {
          return [1, curTotal, 1, curTotal];
        }
      }
    }

    return [2, 1, 1, 10];
  };

  return (
    <>
      {subUnitIdx[curIdx] && (
        (() => {
          const limit = getLimitMax(baseIdxUnit, curIdx);
          limit[3] = Math.max(100,  limit[3]);
          //console.log("limit", limit, "baseIdxUnit:", baseIdxUnit, "curIdx:", curIdx);
          if (limit[1] > limit[0]) {
            return (
              <VBSelectNum
                baseIdx={limit[2]}
                valueMin={limit[0]}
                valueMax={limit[1]}
                rowMax={limit[3]}
                selection={selection}
                setSelection={setSelection}
              />
            );
          }
          return null;
        })()
      )}
    </>
  );
};

export default VBSelectNumLimit;
