import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/lenumv_schema.json';
import nschema from './json/noteString_schema.json';
import uischema from './json/lenumv_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { updateAllEnumValue, reverseAllEnumValue  } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
  isNote?: boolean; //TBD:uischema delete elements.options.detail.elements[0] 
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback, isNote}) => {
  const [data, setData] = useState<any>(editComp);
  const isEnum = (isNote === undefined ? true : !isNote)

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  const debouncedSave = useCallback(
    _.debounce((newData) => {
        if(fcallback){
            fcallback(newData);
        }
    }, 100),
    []
  );

  useEffect(() => {
      if (isEnum) {
          let initData = updateAllEnumValue(data);
          setData(initData);
      }
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    let updateData = {...newData};
    if (isEnum) {
        updateData = reverseAllEnumValue(newData);
        let initData = updateAllEnumValue(updateData);
        if (JSON.stringify(initData) !== JSON.stringify(data)) {
            console.log("update initData:", initData);
            setData({...initData}); //TBC: deep copy
        }
    }
    if(updateData  !== data) {
        debouncedSave(updateData);
    }
  }

  return (
        isEnum ?
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
        :
        <JsonForms
          schema={nschema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ECondition;

