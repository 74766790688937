import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
//import schema from './json/condition_schema.json';
//import uischema from './json/condition_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper, Typography } from '@mui/material';
import OpenWeather from './openWeather';

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'

import * as cm from './utils/common.js';
import { AuthContext, makeRequest, addItem, getItem } from '../AuthContext';


interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const TWeather: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>();
  const { formStack, setFormStack, userInfo, setUserInfo } = useContext(AuthContext)!;
  const [CityName, setCityName] = useState<string>("");
  const [weatherStr, setWeatherStr] = useState<string>("");
  const [ condWeather, setCondWeather ] = useState<any>();

  useEffect(() => {
      //console.log("userInfo:", userInfo);
      //Get City Name
      const lCityName = localStorage.getItem("CityName");
      if (lCityName) {
          setCityName(lCityName);
      }

    getItem(userInfo.GPSCondition, cm.TYPE_RESOURCES, userInfo, (response: any) => {
        setCityName(response.ValueGps.Name);
        localStorage.setItem("CityName", response.ValueGps.Name);
    });

    const lWeatherStr = localStorage.getItem("weatherStr");
    setWeatherStr(lWeatherStr ? lWeatherStr : "0°C~0°C");
    //Get Weather
    getItem(editComp, cm.TYPE_RESOURCES, userInfo, (response: any) => {
        setCondWeather(response);
        console.log("TWeather getItem response:", response);
        let subCompose = response.ValueCompose.SubComposeR;
        //get SubComposeR which key start with "0"
        let today = _.filter(subCompose, (value, key) => {
            return key.startsWith("0");
        });
        if (today.length > 0) {
            let todayTemp = today[0];
            let tempKeys = Object.keys(todayTemp.CRange);
            let low = todayTemp.CRange[tempKeys[0]];
            let high = todayTemp.CRange[tempKeys[1]];
            //remove digit after "."
            let lowStr = low.CurrentValue.toString().split(".")[0];
            let highStr = high.CurrentValue.toString().split(".")[0];

            console.log("todayTemp:", todayTemp);
            let weaStr = "";
            let weaKeys = Object.keys(todayTemp.CEnum);
            if (weaKeys.length > 0) {
                let wea = todayTemp.CEnum[weaKeys[0]];
                let idxs = wea.CurrentValue;
                let allEnum = wea.AllEnumValue
                if (idxs.length > 0 && allEnum.length > 0 && allEnum.length > idxs[0] && allEnum[idxs[0]]) {
                    let idx = idxs[0];
                    weaStr = allEnum[idx].Note;
                }
            }
            let weatherStr = weaStr+ " " + lowStr + "°C~" + highStr + "°C ";

            setWeatherStr(weatherStr);
            localStorage.setItem("weatherStr", weatherStr);
        }
    });
  }, []);

  const clickJumpWeather = () => {
    setFormStack([...formStack, {type: "condition", uuid: editComp, data: condWeather, fcallback: (newData: any) => {
    } }]);
  }

  return (
    <Typography 
        variant="body2"
        onClick={clickJumpWeather}
        style={{cursor: "pointer"}}
        >
      <span role="img" aria-label="cloud"></span> {CityName + " " + weatherStr} 
    </Typography>
  );
};

export default TWeather;

