import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import ReminderPicker from './ReminderPicker';


const ReminderPickerControl: React.FC<ControlProps> = ({ data, handleChange, path, uischema }) => {

  return (
    <ReminderPicker 
      label={uischema.label || 'Reminder'}
      duration={data}
      onDurationChange={(data) => handleChange(path, data)}
    />
  );
};


export default withJsonFormsControlProps(ReminderPickerControl);
