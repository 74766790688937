import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
//import schema from './json/condition_schema.json';
//import uischema from './json/condition_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper } from '@mui/material';

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'

import * as cm from './utils/common.js';
import { AuthContext, makeRequest, addItem, getItem } from '../AuthContext';

const schema = {
    type: 'object',
    properties: {
        ValueRange: { 
            type: 'object' ,
            properties: {
                CurrentValue: { type: 'number' }
            }
        }
                
    }
};

const uischema = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            format: 'ownRating',
            scope: '#/properties/ValueRange/properties/CurrentValue'
        }
    ]
};

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const TMood: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>();
  const { userInfo, setUserInfo } = React.useContext(AuthContext)!;

  const debouncedSave = useCallback(
    _.debounce((newData) => {
      makeRequest('put', 'condition', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        setData(respData);
        localStorage.setItem("CondMoodValue", JSON.stringify(respData));

        if(fcallback){
            fcallback(respData, "Mood");
        }
      });
    }, 100),
    []
  );


  useEffect(() => {
    let lMoodValueStr = localStorage.getItem("CondMoodValue");
    if (lMoodValueStr) {
        let lMoodValue = JSON.parse(lMoodValueStr);
        setData(lMoodValue);
    }
        getItem(editComp, cm.TYPE_RESOURCES, userInfo, (item: any) => {
            setData(item);
            localStorage.setItem("CondMoodValue", JSON.stringify(item));
        });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleMainChange = (newData: any) => {
    if(newData !== data) {
        console.log("handleMainChange newData:", newData.ValueRange);
        newData.ValueJson = JSON.stringify(newData.ValueRange);
        debouncedSave(newData);
    }
  }

  return (
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleMainChange(data)}
        />
  );
};

export default TMood;

