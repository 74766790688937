import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper, Typography, Badge } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'

import * as cm from './utils/common.js';
import { AuthContext, makeRequest, addItem, getItem } from '../AuthContext';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const TPeople: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>();
  const { formStack, setFormStack, userInfo, setUserInfo } = useContext(AuthContext)!;
  const [peopleData, setPeopleData] = useState<any>({name: "独自", num: 0});
  const [condPeople, setCondPeople] = useState<any>();

  const getPeopleStatus = (elems: any) => {
    let status: any[] = [];
    if (elems && elems.length > 0) {
        elems.forEach((elem: any) => {
            let current = elem.Condition.ValueEnum.CurrentValue;
            if (current && current.length > 0 && current.includes(0)) {
                status.push(elem.Name);
            }
        });
    }
    console.log("People status:", status);
    return status;
  }

  const updatePeopleStatus = (elems: any) => {
      let people = getPeopleStatus(elems)
      let newPeopleData ={
          name: people.join(","),
          num: people.length
      }
      if(newPeopleData.num === 0){
          newPeopleData.name = "独自";
      }
      setPeopleData(newPeopleData);
  }



  useEffect(() => {
        console.log("Peple userInfo", userInfo)
        let lPeopleStr = localStorage.getItem("PeopleElems");
        if (lPeopleStr) {
            let lPeopleElems = JSON.parse(lPeopleStr);
            updatePeopleStatus(lPeopleElems);
        }
        let peopleUserInfo = userInfo;
        getItem(peopleUserInfo.PeopleCate, cm.TYPE_CATEGORY, peopleUserInfo, (response: any) => {
            setCondPeople(response)
            console.log("People Cate response:", response);
            peopleUserInfo.ParaInt = 1;
            getItem(peopleUserInfo.PeopleCate, cm.TYPE_CATE_ELEM, peopleUserInfo, (newElems: any) => {
                console.log(newElems)
                updatePeopleStatus(newElems);
                //covert to json and save to local storage
                let newElemsJson = JSON.stringify(newElems);
                localStorage.setItem("PeopleElems", newElemsJson);
                peopleUserInfo.ParaInt = 0;
            });
        });
  }, []);

 
  const jumpCateToggle = () => {
    let peopleStr = localStorage.getItem("PeopleElems");
    if (!peopleStr) {
        return;
    }
    let peopleElems = JSON.parse(peopleStr);
    setFormStack([...formStack, {type: "TogglePeople", uuid: condPeople.ID, data: peopleElems, 
        fcallback: (newData: any) => {
            let newUserInfo = userInfo;
            console.log("TTPeople newData:", newData);
            newUserInfo.ParaInt = 1;
            getItem(condPeople.ID, cm.TYPE_CATE_ELEM, newUserInfo, (newElems: any) => {
                updatePeopleStatus(newElems);
                newUserInfo.ParaInt = 0;
                localStorage.setItem("PeopleElems", JSON.stringify(newElems));
                if(fcallback) {
                    fcallback(newElems, "People");
                }
            });
        }
    }]);
  }


  return (
	<Typography
	  variant="body2"
	  style={{ cursor: 'pointer' }}
	  onClick={() => jumpCateToggle()}
      component="span"
	>
	  {peopleData.name}
	  <Box sx={{ position: 'relative', display: 'inline-flex', verticalAlign: 'middle' }}>
		<GroupIcon sx={{ fontSize: 20, position: 'relative', zIndex: 1 }} />
		<Badge
		  badgeContent={peopleData.num}
		  //color="primary"
		  sx={{
			position: 'absolute',
			top: '0px',
			right: '0px',
			zIndex: 0,
			fontSize: 8,
		  }}
		/>
	  </Box>
	</Typography>
  );
};

export default TPeople;

