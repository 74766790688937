import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/propos_schema.json';
import uischema from './json/propos_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper } from '@mui/material';
import { AuthContext, makeRequest, addItem, getItem } from '../AuthContext';

import ELRange from './ELRange';
import ELEnum  from './ELEnum'
import ELGPS from './ELGPS'
import ELCompose from './ELCompose'

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'

import * as cm from './utils/common.js';

interface EditLogicPropos {
  editComp: any;
  fcallback?: any;
}

const EditPP: React.FC<EditLogicPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);
  const [condData, setCondData] = useState<any>();
  const { globalData, setGlobalData } = useContext(AuthContext)!;
  const { userInfo } = React.useContext(AuthContext)!;
  const [enums, setEnums] = useState<any[]>();

  const debouncedSave = useCallback(
    _.debounce((newData) => {
      makeRequest('put', 'propos', newData, {}, (response) => {
        let respData = JSON.parse(response.data);

        if (response.code !== 200) {
            console.log("response:", response);
            setData(respData);
        }
        console.log("Propos respData:", respData.Type, "condData Type:", condData && condData.LogicType); //TBD: condData is undefined after getItem
        //change logic type sync with condition in backend
        if (condData && condData.LogicType !== respData.Type) {
            let newCondData = {...condData};
            newCondData.LogicType = respData.Type;
            setCondData(newCondData);
            debouncedCondSave(newCondData);
            debouncedSave.flush();
        }
        if(fcallback){
            fcallback(respData);
        }
      });
    }, 1000),
    [condData, data]
  );


  const debouncedCondSave = useCallback(
    _.debounce((newData) => {
      makeRequest('put', 'condition', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        console.log("Propos condData:", respData);
        if (response.code !== 200) {
            console.log("response:", response);
            setCondData(respData);
        }

        if(respData.LogicType === cm.LOGIC_TYPE_ENUM) {
            console.log("Propos setEnums:", respData.ValueEnum.AllEnumValue);
            setEnums(respData.ValueEnum.AllEnumValue);
        }
        //change logic type sync with propos in backend
        if(respData.LogicType !== data.Type) {
            let newLogicData = {...data};
            newLogicData.Type = condData.LogicType;
            debouncedSave(newLogicData);
        }
      });
    }, 1000),
    [condData, data]
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Logic Elem";
      setGlobalData(newGlobalData);

      console.log("Propos editComp:", editComp);

      if (editComp.ValueId && editComp.ValueId !== 0 && editComp.ValueId !== "00000000-0000-0000-0000-000000000000") {
        getItem(editComp.ValueId, cm.TYPE_RESOURCES, userInfo, (response: any) => {
            console.log("Propos getItem cond:", response);
            if(response.LogicType === cm.LOGIC_TYPE_ENUM) {
                setEnums(response.ValueEnum.AllEnumValue);
            }
            setCondData(response);
        });
      }

      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);


  useEffect(() => {
    return () => {
      debouncedCondSave.flush();
    };
  }, [debouncedCondSave]);

 
  const handleDataChange = (newData: any) => {
      let topData = {...data};
      console.log("newData:", newData);
      topData.RestrictionJsonValue = JSON.stringify(newData);
      debouncedSave(topData);
  }


  const handleMainChange = (newData: any) => {
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

  const handleCondChange = (newData: any) => {
    let newJson = JSON.stringify(newData);
    console.log("EPropos Cond newData:", newData, "valueJson:", condData.ValueJson);
    if(newJson !== condData.ValueJson) {
      let topData = {...condData};
      topData.ValueJson = newJson;
      console.log("EPropos Cond save");
      debouncedCondSave(topData);
    }
  }


  return (
        <div>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={customRenderers}
              cells={materialCells}
              onChange={({ errors, data }) => handleMainChange(data)}
            />
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            {(data.Type === cm.LOGIC_TYPE_RANGE || data.Type === cm.LOGIC_TYPE_CATEGORY) && 
                <ELRange editComp={data.TypeRange} fcallback={handleDataChange} />
            }
            {(data.Type === cm.LOGIC_TYPE_FREE_TIME) && 
                <ELRange editComp={data.TypeRange} fcallback={handleDataChange} isTime={true}/>
            }
            {(data.Type === cm.LOGIC_TYPE_ENUM) && 
                <ELEnum editComp={data.TypeEnum} allEnums={enums} fcallback={handleDataChange} />
            }
            {(data.Type === cm.LOGIC_TYPE_GPS) && 
                <ELGPS editComp={data.TypeGps} fcallback={handleDataChange} />
            }
            {(data.Type === cm.LOGIC_TYPE_COMPOSE) && 
                <ELCompose editComp={data.TypeCompose} fcallback={handleDataChange} />
            }
            </Paper>

            {(condData && condData.LogicType === cm.LOGIC_TYPE_RANGE) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELRangeV editComp={condData.ValueRange} fcallback={handleCondChange} />
            </Paper>
            }
            {(condData && condData.LogicType === cm.LOGIC_TYPE_ENUM) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELEnumV editComp={condData.ValueEnum} fcallback={handleCondChange} />
            </Paper>
            }
            {(condData && condData.LogicType === cm.LOGIC_TYPE_GPS) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELGPSV editComp={condData.ValueGps} fcallback={handleCondChange} />
            </Paper>
            }
            {(condData && condData.LogicType === cm.LOGIC_TYPE_COMPOSE) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELComposeV editComp={condData.ValueCompose} fcallback={handleCondChange} />
            </Paper>
            }
        </div>
  );
};

export default EditPP;
