import React, { useState, useContext } from 'react';
import { Button, TextField, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext, makeRequest } from './AuthContext';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setJwt, setUserInfo } = useContext(AuthContext)!;
  const [inloading, setInLoading] = useState(false);

  const handleLoginFake = async () => {
    // Simulate login and JWT storage    
    const fakeJWT = 'fake-jwt-token';    
    setJwt(fakeJWT);
    navigate('/app');                    
  };

  const config = {
	  headers: {
		'Access-Control-Allow-Origin': '*'
	  }
	};

  const handleLogin = async () => {
    setInLoading(true);
    setError('Loading...');
    makeRequest('put', '/auth', { Email: username, password }, config, async(data) => {
        const retJson = JSON.parse(data.data);
        setJwt({
            Username: retJson.Email,
            Expire: retJson.Expire,
            UserId: retJson.UserId,
            Token: retJson.Token
        });

        makeRequest('get', '/user', { id: retJson.UserId}, { headers : { token: retJson.Token }}, async(dataUser) => {
            const user = JSON.parse(dataUser.data);
            setInLoading(false);
            setUserInfo(user);
            navigate('/app');
        });
        setInLoading(false);
    });
  };

  return (
    <Container maxWidth="xs"
      style={{ //center align
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>Login</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Email"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={inloading}
      />
      <TextField
        label="Password"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        disabled={inloading}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
      />
      <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
        Login
      </Button>
      <Button color="secondary" fullWidth onClick={() => navigate('/register')}>
        Register
      </Button>
    </Container>
  );
};

export default Login;
