import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker as DateTimePickerRaw } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface DateTimePickerProps {
  label: any;
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}

  const getLocalISOTime = () => {
    let d = new Date();
    let offset = -d.getTimezoneOffset();
    let n = new Date(d.getTime() + offset*60000);
    return n.toISOString()
    //return n.toISOString().slice(0, 19);
  }

const DateTimePicker: React.FC<DateTimePickerProps> = ({ label, value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePickerRaw 
        label={label}
        value={value}
        onChange={onChange}
        format="YYYY-MM-DD HH:mm"
        ampm={false} 
        sx={{ width: '100%' }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;

