import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/condAvail_schema.json';
import uischema from './json/condAvail_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  const debouncedSave = useCallback(
    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'condAvail', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        addNameToIncludeA(respData);
        if(data === newData && respData !== newData) {//TBD
            setData(respData);
        }
        console.log("condAvail respData:", respData);
        if(fcallback){
            fcallback(respData);
        }
      });
    }, 1000),
    []
  );


  const getLocalISOTime = (dateStr:string) => {
    //let d = new Date();
    let d = new Date(dateStr);
    let offset = -d.getTimezoneOffset();
    let n = new Date(d.getTime() + offset*60000);
    return n.toISOString()
  }


  //format StartDate as "YYYY-MM-DD HH:mm:ss" name to IncludeA's name
  const addNameToIncludeA = (condA: any) => {
    let condAvail = {...condA};
    if (condAvail.IncludeA && condAvail.IncludeA.length > 0) {
        condAvail.IncludeA.forEach((item: any) => {
            let localStr = getLocalISOTime(item.StartDate);
            item.Name = localStr.substring(0, 19)
            item.Name = item.Name.replace("T", " ");
        });
    }
    if (condAvail.ExcludeA && condAvail.ExcludeA.length > 0) {
        condAvail.ExcludeA.forEach((item: any) => {
            let localStr = getLocalISOTime(item.StartDate);
            item.Name = localStr.substring(0, 19)
            item.Name = item.Name.replace("T", " ");
        });
    }
    console.log("add Name To Include condAvail", condAvail)
  }


  const addCurrentDate = (condA: any) => {
    let condAvail = {...condA};
    if (condAvail.IncludeA && condAvail.IncludeA.length > 0) {
        condAvail.IncludeA.forEach((item: any) => {
            if (!item.StartDate) {
                item.StartDate = new Date().toISOString();
                item.UpdateReminder = item.StartDate;
            }
        });
        //sort by StartDate
        condAvail.IncludeA.sort((a: any, b: any) => {
            if (a.StartDate < b.StartDate) {
                return -1;
            }
            if (a.StartDate > b.StartDate) {
                return 1;
            }
            return 0;
        });
    }
    if (condAvail.ExcludeA && condAvail.ExcludeA.length > 0) {
        condAvail.ExcludeA.forEach((item: any) => {
            if (!item.StartDate) {
                item.StartDate = new Date().toISOString();
                item.UpdateReminder = item.StartDate;
            }
        });
        //sort by StartDate
        condAvail.ExcludeA.sort((a: any, b: any) => {
            if (a.StartDate < b.StartDate) {
                return -1;
            }
            if (a.StartDate > b.StartDate) {
                return 1;
            }
            return 0;
        });
    }
    console.log("new condAvail", condAvail)
  }


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Condition Avail Duration";
      setGlobalData(newGlobalData);
      addNameToIncludeA(data);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    if(newData !== data) {
        addCurrentDate(newData);
        newData.IncludeJson = JSON.stringify(newData.IncludeA);
        newData.ExcludeJson = JSON.stringify(newData.ExcludeA);
        setData(newData);
        console.log("Cond Avail newData:", newData, " data:", data);
        debouncedSave(newData);
    }
  }

  return (
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ECondition;

