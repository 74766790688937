import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext, makeRequest, addItem, delItem, getItem } from '../AuthContext';
import { Box, Grid, Select, MenuItem, Autocomplete, TextField } from '@mui/material';

import * as cm from './utils/common';
import _ from 'lodash';

interface VFastInputProps {
  selectedItem: string;
  userInfo: any;
  onBlur:() => void;
  onSearch: (todos: any) => void;
}

const VFastInput: React.FC<VFastInputProps> = ({ selectedItem, userInfo, onBlur, onSearch }) => {
  const { setJwt, formStack, setFormStack } = useContext(AuthContext)!;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedType, setSelectedType] = useState(cm.TYPE_TODO);
  const [acItems, setAcItems] = useState<any[]>([]);
  const [nameOperation, setNameOperation] = useState<any>({newName: "", template: "", operation: ""});

  const [openSelect, setOpenSelect] = useState(false);

  const addItemown = () => {
      let cm_sel = cm.baseType[selectedType];
      addItem(selectedItem, selectedType, searchQuery, userInfo, (newItem:any) => {
          if (selectedType === cm.TYPE_TODO) {
              if (onSearch) {
                onSearch(newItem); // 更新父组件的todos
              }
              setSearchQuery('');
          }else{
           setFormStack([...formStack, {type: cm_sel.url, uuid: newItem.ID, data: newItem, 
            fcallback: (newData: any) => {
            }
           }]);
          }
      });
  }

  const deleteItem = (item:any) => {
      delItem(item.ID, selectedType, userInfo, (response: any) => {
          let newItems = acItems.filter((item) => item.ID !== response.ID);
          setAcItems(newItems);
      });
  }

  const editItem = (item:any) => {
      let cm_sel = cm.baseType[selectedType];

        console.log("editItem: ", item);
        getItem(item.ID, cm_sel.value, userInfo, (response: any) => {
            if (response) {
               setFormStack([...formStack, {type: cm_sel.url, uuid: item.ID, data: response, 
                fcallback: (newData: any) => {
                }
               }]);
            }
        });

/*
    if (onBlur) {
      onBlur();
    }
    */
  }
  
  const getNameAndTemplate = (value: string) => {
    let newName = ""
    let template = ""

    if (!value) {
        return {newName, template, operation: ""};
    }

    //根据value中的@和#来判断是什么操作
    //delete: #template
    //edit: @template
    //new: newName
    //clone: newName @template
    let operation = ""
    if (value.length > 0) {
        if (value[0] == '@') { //edit
            template = value.substr(1)
            operation = "edit"
        } else if (value[0] == '#') { //delete
            template = value.substr(1)
            operation = "delete"
        } else { //new
            newName = value
            operation = "new"
            let idx = value.indexOf('@')
            if (idx > 0) { //clone
                newName = value.substr(0, idx)
                template = value.substr(idx+1)
                operation = "clone"
            }
        }
    }
    //console.log("newName: ", newName, " template: ", template, " operation: ", operation)
    setNameOperation({newName, template, operation});
    return {newName, template, operation};
 }


 const updateTemplate = (value: string) => {
    let {newName, template, operation} = getNameAndTemplate(value);
    
    if (template.length > 1) {
        let cm_sel = cm.baseType[selectedType]
        // query struct
        let ac = {
            userID: userInfo.ID,
            listID: userInfo.DefaultList,
            autoType: cm_sel.act,
            queryName: template,
            //id: "",
            isIDS: false,
            excludeIds: [],
            offset: 0,
            apiMethod: 0,
            apiPara: ""
        }

        console.log("updateTemplate: ", ac, "selectedType: ", selectedType);

        //response struct: var id, name, extraJson: String?
        makeRequest('get', "autoCompelete", ac, {}, (data) => {
            const resp = JSON.parse(data.data);
            if (resp && resp.length > 0) {
                setAcItems(resp);
            }
        });
    }else{
        //setAcItems([{ID: "00000000-0000-0000-0000-000000000000", Name: value}]);
    }

    if (value.length === 0) {
        setAcItems([]);
    }
 }

 const debounceSearch = useCallback(
    _.debounce((value: string) => {
      updateTemplate(value);
    }, 500), // 500ms 延迟
    []
  );

  // 清理防抖
  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]); 


  const handleFastInputChange = (event: React.ChangeEvent<{}>, value: string, reason:string) => {
    //console.log("handleFastInputChange: ", value, " reason: ", reason);
    if (reason !== 'input' && reason !== 'clear') {
        return;
    }
    console.log("handleFastInputChange: ", value, " reason: ", reason);
    setSearchQuery(value);
    updateTemplate(value);
    //debounceSearch(value);  //bug: can't get the latest selectedType
  };

  useEffect(() => {
    let selectedTypeStr = localStorage.getItem("FastselectedType");
    if (selectedTypeStr) {
        setSelectedType(parseInt(selectedTypeStr));
    }
  }, []);

  const handleTypeChange = (event: any) => {
    let newType = event.target.value as number;
    if (newType === selectedType) {
        return
    }
    localStorage.setItem("FastselectedType", newType.toString());
    setSelectedType(newType);
    setAcItems([]);
  };


  const handleSelChange = (event:any, value:any, reason:any) => {
    console.log("handleSelChange: ", value, " reason: ", reason);
    if (reason !== "selectOption") {
        return;
    }
    if (reason === "selectOption") {
        setOpenSelect(true);
    }
    if (value) {
        let {newName, template, operation} = nameOperation;
        console.log("handleSelChange: ", value, " newName: ", newName, " template: ", template, " operation: ", operation);
        let setQuery = true;
        if (operation === "new") {
            addItemown();
        }else if (operation === "delete") {
            setQuery = false;
            deleteItem(value);
        }else if (operation === "edit") {
            editItem(value);
        }else if (operation === "clone") {
            //todo add code here
        }
        if (setQuery) {
            //setSearchQuery('');
        }
    }
  }


  const handleSearchKeyDown = (e: any) => {
    const event = e as React.KeyboardEvent<HTMLDivElement>;
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to stop scrolling when pressing Enter
      const {newName, template, operation} = getNameAndTemplate(searchQuery);
      console.log("handleSearchKeyDown: ", searchQuery, " newName: ", newName, " template: ", template, " operation: ", operation);
      if (operation === "new") {
          addItemown();
          setSearchQuery('');
      }
    }
  }


  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0} alignItems="center">
        {/* 选择不同类型的下拉框 */}
        <Grid item sx={{ display: 'flex' , flexShrink: 0}}>
          <Select
            fullWidth
            value={selectedType}
            onChange={handleTypeChange}
            displayEmpty
            sx={{ 
                backgroundColor: 'transparent',
                color: 'inherit',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            }}
          >
            {cm.baseType.map((type, i) => (
                <MenuItem key={i} value={i}>
                  {type.name}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        {/* 自动完成搜索框 */}
        <Grid item xs>
          <Autocomplete
            freeSolo
            options={acItems}
            filterOptions={(x) => x} // Disable filtering
            inputValue={searchQuery}
            onInputChange={handleFastInputChange}
            fullWidth
            open={openSelect}
            onOpen={() => setOpenSelect(true)}
            onClose={(event, reason) => {
                console.log("onClose: ", event, " reason: ", reason);
            }}
            getOptionLabel={(option) => option.Name || ""} 
            onChange={handleSelChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search…"
                fullWidth
                variant="outlined"
                autoFocus
                onKeyDown={handleSearchKeyDown}  // 使用内部的 handleSearchKeyDown
                sx = {{
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                          border: 'none',
                      },
                    },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VFastInput;

