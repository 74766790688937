import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import VTodoGroup from './VTodoGroup';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc); // use utc plugin

const TodoGroupControl: React.FC<ControlProps> = ({ data, handleChange, path, schema, uischema }) => {

  return (
    <VTodoGroup
      data={data}
      onChange={(newData:any) => handleChange(path, newData)}
    />
  );
};


export default withJsonFormsControlProps(TodoGroupControl);
