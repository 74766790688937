import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography, Autocomplete, List, ListItem, ListItemText, Paper, Collapse, Divider, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { AuthContext, makeRequest, addItem, getItem } from '../../AuthContext';

import * as cm from '../utils/common';

interface VAutoCompProps {
  autoType: any;
  currentString: string;
  paraStr: string[];
  paraInt: number;
  oneMapName: string;
  enableEdit: boolean;
  compSettingRaw: number;
  setCurrentString: (value: string) => void;
  setParaInt: (value: number) => void;
}

const VAutoComp: React.FC<VAutoCompProps> = ({
  autoType, currentString, paraStr, paraInt, oneMapName, enableEdit, compSettingRaw, setCurrentString, setParaInt
}) => {
  const { setJwt, formStack, setFormStack, userInfo } = useContext(AuthContext)!;

  const [isEditable, setIsEditable] = useState(false);
  const [options, setOptions] = useState<any[]>([]); //for choose from query
  const [inputValue, setInputValue] = useState('');

  const [selectedValue, setSelectedValue] = useState("");
  const [acItems, setAcItems] = useState<any[]>([]); //for show current value
  const [compSetting, setCompSetting] = useState<number>(compSettingRaw);

  const [naviData, setnaviData] = useState<any>();
  const [naviSubData, setnaviSubData] = useState<any>();

  const [baseType, setBaseType] = useState<any>();
  const [fastType, setFastType] = useState<string>("");
  const [subName, setSubName] = useState<string>("");

  let paraUser = { ...userInfo, paraStr: paraStr, paraInt: paraInt};

  const getIds = (cate:string, elems: string) => {
        let cateArray = cate.split(",");
        let elemsArray = elems.split(",");
        let newCategory = cateArray.filter((item) => item !== "");
        let newElems = elemsArray.filter((item) => item !== "");
        return {newCategory, newElems};
  }

  const clearSubFast = () => {
      setFastType("");
      setSubName("");
      setnaviSubData({});
  }

  useEffect(() => {
    let base_idx = cm.baseType[0] 
    for (let i = 0; i < cm.baseType.length; i++) {
        if (cm.baseType[i].url === autoType) {
            base_idx = cm.baseType[i];
          break;
        }
    }
    setBaseType(base_idx);
    if (currentString && currentString !== "" && currentString !== "00000000-0000-0000-0000-000000000000") {
        if (compSetting === cm.ACOMP_ONLY_ONE) {
                setSelectedValue('...');
                getItem(currentString, base_idx.value, paraUser, (response: any) => {
                    if (response) {
                        setSelectedValue(response.Name);
                        //console.log("init response:", response);
                        setnaviData(response);
                        //logic type has propos
                        if(!response) {
                            return;
                        }
                        if (base_idx.value === cm.TYPE_LOGIC) {
                            let {newCategory, newElems} = getIds(response.SubTodoLogicJson, response.SubPropositionJson);
                            console.log("fast logic response:", response, " newCategory length:", newCategory.length, " newElems length:", newElems.length);
                            if (newCategory.length === 0 && newElems.length === 1) {
                                let subprops = newElems[0];
                                getItem(subprops, cm.TYPE_LOGIC_ELEM, paraUser, (response: any) => {
                                    if (response) {
                                        setFastType("propos");
                                        console.log("init subProp response:", response);
                                        setSubName(response.Name);
                                        setnaviSubData(response);
                                    }
                                });
                            }else if (newCategory.length === 1 && newElems.length === 0) {
                                let subCate = newCategory[0];
                                getItem(subCate, cm.TYPE_LOGIC, paraUser, (response: any) => {
                                    if (response) {
                                        setFastType("logic");
                                        console.log("init subCate response:", response);
                                        setSubName(response.Name);
                                        setnaviSubData(response);
                                    }
                                });
                            }else{
                                clearSubFast();
                            }
                        }else if (base_idx.value === cm.TYPE_CATEGORY) {
                            let {newCategory, newElems} = getIds(response.SubCategoryIDs, response.Elems);
                            console.log("fast cate response:", response, "newCategory length:", newCategory.length, "newElems length:", newElems.length);
                            if (newCategory.length === 1 && newElems.length === 0) {
                                let subCate = newCategory[0];
                                getItem(subCate, cm.TYPE_CATEGORY, paraUser, (response: any) => {
                                    if (response) {
                                        setFastType("category");
                                        console.log("init subCate response:", response);
                                        setSubName(response.Name);
                                        setnaviSubData(response);
                                    }
                                });
                            }else if (newCategory.length === 0 && newElems.length === 1) {
                                let subElem = newElems[0];
                                getItem(subElem, cm.TYPE_CATE_ELEM, paraUser, (response: any) => {
                                    if (response) {
                                        setFastType("elem");
                                        console.log("init subElem response:", response);
                                        setSubName(response.Name);
                                        setnaviSubData(response);
                                    }
                                });
                            }else{
                                clearSubFast();
                            }
                        }else if (base_idx.value === cm.TYPE_RESOURCES) {
                            if (response.LogicType === 4) { //logic Category
                                let subid = response.GenerateConditonCategoryId;
                                if (subid !== "" && subid !== "00000000-0000-0000-0000-000000000000") {
                                    getItem(subid, cm.TYPE_CATEGORY, paraUser, (response: any) => {
                                        if (response) {
                                            setFastType("category");
                                            console.log("init conddition cate response:", response);
                                            setSubName(response.Name);
                                            setnaviSubData(response);
                                        }
                                    });
                                }else{
                                    clearSubFast();
                                }
                            }else{
                            clearSubFast();
                            }
                        }else{
                            clearSubFast();
                        }
                    }
                });
        } else if (compSetting === cm.ACOMP_ORDER) {
            //AutoCompelete(userID: bData.myuser.id, autoType: autoType.rawValue, queryName: curretString, isIDS: true, excludeIds: excludeIds)
            // query struct
            let ac = {
                userID: userInfo.ID,
                listID: userInfo.DefaultList,
                autoType: base_idx.act,
                queryName: currentString,
                //id: "",
                isIDS: true,
                excludeIds: [],
                offset: 0,
                apiMethod: 0,
                apiPara: ""
            }
            //console.log("init ac:", ac);

            //response struct: var id, name, extraJson: String?
            makeRequest('get', "autoCompelete", ac, {}, (data) => {
                const resp = JSON.parse(data.data);
                //console.log("init ac resp:", resp);
                if (resp && resp.length > 0) {
                    setAcItems(resp);
                }
            });
        }
    }
  }, []);

  const wrapperSetAcItems = (items: any[]) => {
    //update currentString join items.ID with "," don't end with ","
    if (compSetting === cm.ACOMP_ORDER) {
        let newString = "";
        for (let i = 0; i < items.length; i++) {
          newString += items[i].ID;
          if (i < items.length - 1) {
            newString += ",";
          }
        }
        setCurrentString(newString);
        setAcItems(items);
    }
  }

  const setEmptyInputValue = () => {
    setInputValue('');
    setOptions([]);
  }

  const handleKeyPress = (e: any) => {
    const event = e as React.KeyboardEvent<HTMLDivElement>;
    //console.log("event.key:", event.key);
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to stop scrolling when pressing Enter
      let mtype = baseType.value;

      addItem("", mtype, inputValue, paraUser, (newItem: any) => {
        console.log("mytep:", mtype, "newItem:", newItem);

        if (compSetting === cm.ACOMP_ONLY_ONE) {
            setCurrentString(newItem.ID);
            setSelectedValue(newItem.Name);
            setnaviData(newItem)
        }else if (compSetting === cm.ACOMP_ORDER) {
            let found = false;
            for (let i = 0; i < acItems.length; i++) {
              if (acItems[i].ID === newItem.ID) {
                found = true;
                break;
              }
            }
            if (!found) {
              //add object: var id, name, extraJson: String?
              let newAC = {ID:newItem.ID, Name: newItem.Name, extraJson: JSON.stringify(newItem)};
              wrapperSetAcItems([...acItems, newAC]);
            }
        }
        
        setEmptyInputValue();   
      });
    }
  }

  //search for AutoComplete
  const handleInputChange = async (event: any, value: any, reason:any) => {
    if (reason !== 'input' && reason !== 'clear') {
        return;
    }
    setInputValue(value);
    if (value) {
        //AutoCompelete(userID: bData.myuser.id, autoType: autoType.rawValue, queryName: curretString, isIDS: true, excludeIds: excludeIds)
        // query struct
        let ac = {
            userID: userInfo.ID,
            listID: userInfo.DefaultList,
            autoType: baseType.act,
            queryName: value,
            //id: "",
            isIDS: false,
            excludeIds: acItems.map((item) => item.ID),
            offset: 0,
            apiMethod: 0,
            apiPara: ""
        }

        //response struct: var id, name, extraJson: String?
        makeRequest('get', "autoCompelete", ac, {}, (data) => {
            const resp = JSON.parse(data.data);
            console.log("resp:", resp, "resp type is", typeof(resp));
            if (resp && resp.length > 0) {
                if (autoType === "todo") {
                    if (paraInt !== 0) {
                        let newItems = resp.filter((item: any) => {
                            let extraJson = JSON.parse(item.ExtraJson);
                            return extraJson.TodoType !== 0;
                        });
                        setOptions(newItems);
                        return;
                    }
                }
                setOptions(resp);
            }
        });
    } else {
      setOptions([]);
    }
  };

  // Handle AutoComplete selection
  const handleSelectionChange = (event: any, newValue: any, reason:any) => {
    //console.log("newValue:", newValue, "event:", reason);
    if (reason == "clear") {
        setEmptyInputValue();   
        return;
    }

    if (reason !== 'selectOption') {
        return;
    }

    if (compSetting === cm.ACOMP_ONLY_ONE) {
        getItem(newValue.ID, baseType.value, paraUser, (response: any) => {
            if (response) {
                setnaviData(response);
            }
        });

        setCurrentString(newValue.ID);
        setSelectedValue(newValue.Name);
        
        setIsEditable(false);
        setEmptyInputValue();
    } else if (compSetting === cm.ACOMP_ORDER) {
      //add newValue to acItems when newValue.ID not in acItems
      let found = false;
      for (let i = 0; i < acItems.length; i++) {
        if (acItems[i].ID === newValue.ID) {
          found = true;
          break;
        }
      }
      console.log("found:", found);
      if (!found) {
        wrapperSetAcItems([...acItems, newValue]);
      }
      //remove select value from options
      let newOptions = options.filter((option) => option.ID !== newValue.ID);
      setOptions(newOptions);
    }
  };

  // Handle toggling edit mode
  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const navigateToComponent = () => {
	if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
        clearLabel();
    } else {
      const timeout = setTimeout(() => {
		setFormStack([...formStack, {type: autoType, uuid: selectedValue, data: naviData, 
			fcallback: (newData: any) => {
				console.log("FormStack newData:", newData);
				setnaviData(newData);
				setSelectedValue(newData.Name);
			}
		}]);
        setClickTimeout(null);
      }, 300); // 300ms 是标准的双击间隔
      setClickTimeout(timeout);
    }
  };


  const navigateToSubComp= () => {
    if (fastType != "") {
        setFormStack([...formStack, {type: fastType, uuid: naviSubData.ID, data: naviSubData, fcallback: (newData: any) => {
            console.log(fastType, " callback response:", newData);
            setnaviSubData(newData);
            setSubName(newData.Name);
        } }]);
    }
  };

  // Handle clearing the label (double-click on right label)
  const clearLabel = () => {
    setCurrentString('00000000-0000-0000-0000-000000000000');
    setSelectedValue('');
  };


  const navigateOrder = (acItem:any) => {
    console.log("navigateOrder: ", acItem, "UserInfo Int:", userInfo.ParaInt, " userInfo paraStr:", userInfo.ParaStr);
    getItem(acItem.ID, baseType.value, userInfo, (response: any) => {
        if (response) {
           setFormStack([...formStack, {type: autoType, uuid: acItem.ID, data: response, 
            fcallback: (newData: any) => {
                let newItems = acItems.map((item) => {
                    if (item.ID === newData.ID) {
                        return {ID: newData.ID, Name: newData.Name, extraJson: JSON.stringify(newData)};
                    } else {
                        return item;
                    }
                });
                wrapperSetAcItems(newItems);
            }
           }]);
        }
    });
  };

  const deleteListItem = (acItem: any) => {
      console.log("deleteListItem: ", acItem);
      let newItems = acItems.filter((item) => item.ID !== acItem.ID);
      wrapperSetAcItems(newItems);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography 
                onClick={toggleEditable}
                style={{ 
                    cursor: 'pointer',
                    color: 'blue',
                }}>
              {oneMapName}
            </Typography>

            {compSetting === cm.ACOMP_ONLY_ONE && (
            <Box display="flex" alignItems="center">
            <Typography
              onClick={navigateToComponent}
              style={{ 
                  cursor: 'pointer',
                  color: 'blue',
              }}
            >
              {selectedValue}
            </Typography>

            {subName !== "" && (
                <>
                <Typography  style={{ marginRight: 8, color: 'grey', paddingLeft: 4 }}>{' =>'}</Typography>
                <Typography
                    onClick={navigateToSubComp}
                    style={{ 
                        cursor: 'pointer',
                        color: 'blue',
                    }}
                >
                {subName}
                </Typography>
                </>
            )}
            </Box>
            )}
          </Box>
        {compSetting === cm.ACOMP_ORDER && acItems.length > 0 && (
          <Box marginTop={2}>
            <List 
             style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '10px',
                overflowY: 'auto',
                //maxHeight: '150px',
                maxHeight: '40vh',
                }}
            >
              {acItems.map((item, index) => (
                <div key={index}>
                <ListItem
                  key={index}
                  style={{ 
                      cursor: 'pointer',
                      //outline: '0.1px solid #ccc',
                      color: 'link',
                    }}
                  secondaryAction={
                    isEditable && (
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteListItem(item)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                  }
                >
                  <ListItemText 
                    onClick={() => navigateOrder(item)}
                    primary={item.Name} 
                    />
                </ListItem>
                {index !== acItems.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </Box>
        )}
        {isEditable && (
          <Autocomplete
            fullWidth
            options={options}
            filterOptions={(x) => x} // Disable filtering
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleSelectionChange}
            getOptionLabel={(option) => option.Name || ""} 
            renderInput={(params) => 
                <TextField {...params} 
                    label="Search and Add"
                    onKeyDown={handleKeyPress}
                    variant="outlined" />}
          />
        )}
      </Box>
    </Paper>
  );
};

export default VAutoComp;
