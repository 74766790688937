import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext, makeRequest } from '../AuthContext';
import EditTodo  from './EditTodo';
import EditLogic from './EditLogic';
import EPropos from './EPropos';
import ECond from './ECondition';
import ECate from './ECategory';
import ECateElem from './ECateElem';
import ECondAvail from './ECondAvail';
import ECondUpdate from './ECondUpdate';
import ETodoOpera from './ETodoOpera';
import EUser from './EUser';
import ERecursive from './ERecursive';
import ELComposeV from './ELComposeV';
import TTogglePP from './TTogglePP';


function RFormStack() {
   const { formStack, setFormStack } = useContext(AuthContext)!;

   return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {formStack.map((form, index) => {
                const ftype = form.type;
                const isTopForm = index === formStack.length - 1; // 仅显示顶部的表单
                return (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: formStack.length - index, // 确保正确的堆叠顺序
                            display: isTopForm ? 'block' : 'none', // 仅显示顶部的表单
                            //visibility: isTopForm ? 'visible' : 'hidden', // 仅显示顶部的表单
                            //pointerEvents: isTopForm ? 'auto' : 'none', // 仅顶部表单可交互
                        }}
                    >
                            <>
                                {/*
                                  {name: 'Todo', value: TYPE_TODO, url: 'todo', act: ACT_TODO},
                                  {name: 'Logic', value: TYPE_LOGIC, url: 'logic', act: ACT_LOGIC},
                                  {name: 'Logic Elem', value: TYPE_LOGIC_ELEM, url: 'propos', act: ACT_PROPOS},
                                  {name: 'Resources', value: TYPE_RESOURCES, url: 'condition', act: ACT_CONDITION},
                                  {name: 'Category', value: TYPE_CATEGORY, url: 'category', act: ACT_CATEGORY},
                                  {name: 'Category Elem', value: TYPE_CATE_ELEM, url: 'elem', act: ACT_CATEGORY_ELEM},
                                  {name: 'Valid Time', value: TYPE_VALID_TIME, url: 'condUpdate', act: ACT_CONDITION_UPDATE_DURATION},
                                  {name: 'Valid Time Group', value: TYPE_VALID_GROUP, url: 'condAvail', act: ACT_CONDITIION_AVAIL_DURATION},
                                  {name: 'Trigger', value: TYPE_TRIGGER, url: 'todoOpera', act: ACT_TODO_OPERA},
                                  */
                                }
                                {ftype === 'todo' && <EditTodo todo={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'logic' && <EditLogic logic={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'propos' && <EPropos editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'condition' && <ECond editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'category' && <ECate editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'elem' && <ECateElem editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'condAvail' && <ECondAvail editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'condUpdate' && <ECondUpdate editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'todoOpera' && <ETodoOpera editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'user' && <EUser editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'recursive' && <ERecursive editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} />}
                                {ftype === 'SubComposeR' && <ELComposeV editComp={form.data} fcallback={form.fcallback ? form.fcallback : null} recursive={form.recursive} />}
                                {ftype === 'TogglePeople' && <TTogglePP editComp={form.data} fcallback={form.fcallback ? form.fcallback : null}/>}
                            </>
                    </div>
                );
            })}
        </div>
    );
}


export default RFormStack;

