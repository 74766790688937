import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/logic_schema.json';
import uischema from './json/logic_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';

interface EditLogicPropos {
  logic: any;
  fcallback?: any;
}

const EditLogic: React.FC<EditLogicPropos> = ({ logic, fcallback }) => {
  const [data, setData] = useState<any>(logic);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  //防止 debouncedSave 在组件的生命周期内稳定引用，并且不会因为 handleDataChange 被多次触发而重新创建
  const debouncedSave = useCallback(

    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'logic', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        if (response.code !== 200) {
            console.log("response:", response);
            setData(respData);
        }
        console.log("[Logic]:", respData);
        if(fcallback){
            fcallback(respData);
        }

        //setData(newData);
        //const getLogics = JSON.parse(response.data);
      });
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Logic";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

  return (
    <div>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
    </div>
  );
};

export default EditLogic;

