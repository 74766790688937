import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import DateTimePicker from './DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc); // use utc plugin

const DateTimePickerControl: React.FC<ControlProps> = ({ data, handleChange, path, schema, uischema }) => {
  //data is string, convert it to dayjs
  //var date_data = dayjs(data).utc();
  var date_data = dayjs(data);

  return (
    <DateTimePicker
      label={uischema.label || 'Date and Time'}
      value={date_data}
      onChange={(date) => handleChange(path, date)}
    />
  );
};


export default withJsonFormsControlProps(DateTimePickerControl);
