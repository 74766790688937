import React, { useState } from 'react';
import { MenuItem, FormControl, Select, InputLabel, SelectChangeEvent } from '@mui/material';

interface DurationOption {
  name: string;
  rvalue: number;
}

const roptions: DurationOption[] = [
  { name: 'NONE', rvalue: 3_153_600_000_000_000_000 },
  { name: '1MIN', rvalue: 60 * 1_000_000_000 },
  { name: '3MIN', rvalue: 180 * 1_000_000_000 },
  { name: '5MIN', rvalue: 300 * 1_000_000_000 },
  { name: '10MIN', rvalue: 600 * 1_000_000_000 },
  { name: '15MIN', rvalue: 900 * 1_000_000_000 },
  { name: '30MIN', rvalue: 1_800 * 1_000_000_000 },
  { name: '45MIN', rvalue: 2_700 * 1_000_000_000 },
  { name: '1HOUR', rvalue: 3_600 * 1_000_000_000 },
  { name: '2HOUR', rvalue: 7_200 * 1_000_000_000 },
  { name: '3HOUR', rvalue: 10_800 * 1_000_000_000 },
  { name: '5HOUR', rvalue: 18_000 * 1_000_000_000 },
  { name: '1DAY', rvalue: 86_400 * 1_000_000_000 },
  { name: '2DAY', rvalue: 172_800 * 1_000_000_000 },
  { name: '3DAY', rvalue: 259_200 * 1_000_000_000 },
];

const getIdxFromDuration = (duration: number): number => {
  return roptions.findIndex((option) => option.rvalue === duration) || 0;
};

interface ReminderPickerProps {
  label: any;
  duration: number;
  onDurationChange: (newDuration: number) => void;
}

const ReminderPicker: React.FC<ReminderPickerProps> = ({ label, duration, onDurationChange }) => {
  const [selectedIdx, setSelectedIdx] = useState(getIdxFromDuration(duration));

  const handleChange = (event: SelectChangeEvent<number>) => {
    const idx = Number(event.target.value);
    setSelectedIdx(idx);
    const newDuration = roptions[idx].rvalue;
    onDurationChange(newDuration);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="reminder-picker-label">{label}</InputLabel>
      <Select
        labelId="reminder-picker-label"
        value={selectedIdx}
        label={label}
        onChange={handleChange}
      >
        {roptions.map((option, idx) => (
          <MenuItem key={idx} value={idx}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReminderPicker;

