import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/condition_schema.json';
import uischema from './json/condition_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper } from '@mui/material';

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'

import * as cm from './utils/common.js';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
  onlyShowValue?: boolean;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback, onlyShowValue = false }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  //防止 debouncedSave 在组件的生命周期内稳定引用，并且不会因为 handleDataChange 被多次触发而重新创建
  const debouncedSave = useCallback(

    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'condition', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        console.log("Condition:", respData);
        if (response.code !== 200) {
            console.log("response:", response);
            setData(respData);
        }
        if(fcallback){
            fcallback(respData);
        }
      });
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Condition";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);

 
  const handleMainChange = (newData: any) => {
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

  const handleDataChange = (newData: any) => {
      console.log("ECondition newData:", newData);
      let newJson = JSON.stringify(newData);
      if (data.ValueJson !== newJson) {
          let topData = {...data};
          topData.ValueJson = newJson;
          debouncedSave(topData);
      }
  }

  return (
        <Box>
            {!onlyShowValue &&
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={customRenderers}
              cells={materialCells}
              onChange={({ errors, data }) => handleMainChange(data)}
            />
            }

            {(data.LogicType === cm.LOGIC_TYPE_RANGE) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELRangeV editComp={data.ValueRange} fcallback={handleDataChange} />
            </Paper>
            }
            {(data.LogicType === cm.LOGIC_TYPE_ENUM) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELEnumV editComp={data.ValueEnum} fcallback={handleDataChange} />
            </Paper>
            }
            {(data.LogicType === cm.LOGIC_TYPE_GPS) && !onlyShowValue &&
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELGPSV editComp={data.ValueGps} fcallback={handleDataChange} />
            </Paper>
            }
            {(data.LogicType === cm.LOGIC_TYPE_COMPOSE) && 
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <ELComposeV editComp={data.ValueCompose} fcallback={handleDataChange} />
            </Paper>
            }
        </Box>
  );
};

export default ECondition;

