import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import NoteContent from '../NoteContent'; // 引入新组件
import DateTimePicker from './DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Typography, Box, TextField } from '@mui/material';

dayjs.extend(utc); // use utc plugin

const DateTimePickerControl: React.FC<ControlProps> = ({ data, handleChange, path, schema, uischema }) => {
    const [ showRaw, setShowRaw ] = React.useState(false);
    const label = schema.title ? schema.title : path;
    console.log("data:", data);

  return (
    <Box>
    <Typography variant="h6" component="h6" 
        onClick={() => setShowRaw(!showRaw)}
    >
       {label} 
    </Typography>
    {showRaw ? (
        <TextField
          type="number"
          fullWidth
          label={label}
          variant="outlined"
          value={data}
          multiline
          onChange={(ev) => handleChange(path, ev.target.value)}
        />
    ) : (
        <NoteContent 
            content={data}
        />
    )
    }
    </Box>
  );
};


export default withJsonFormsControlProps(DateTimePickerControl);
