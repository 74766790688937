import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/user_schema.json';
import uischema from './json/user_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext,  makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface EditTodoPropos {
  editComp: any;
  fcallback?: any;
}


const EUser: React.FC<EditTodoPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);
  const { globalData, setGlobalData } = useContext(AuthContext)!;

  const debouncedSave = useCallback(
    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'user', updatedData, {}, (response) => {
        const getTodos = JSON.parse(response.data);
        console.log("getTodos:", getTodos);
      });
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "User";
      setGlobalData(newGlobalData);
      console.log("oldTitle:", oldTitle, "newTitle:", newGlobalData.Title);

      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      }

  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleDataChange = (data: any) => {
    console.log(new Date().toLocaleString(), ":", data);
    setData(data);
    debouncedSave(data);
  }

  return (
    <div>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
    </div>
  );
};

export default EUser;

