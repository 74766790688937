import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import { materialCells } from '@jsonforms/material-renderers';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { TextField, Box, Button, Grid, Typography, Divider } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

import * as cm from './utils/common.js';
import { AuthContext, makeRequest, addItem, getItem } from '../AuthContext';


interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const TPeople: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [items, setItems] = useState<any>(editComp);
  const { formStack, setFormStack, userInfo, setUserInfo } = useContext(AuthContext)!;
  const { globalData, setGlobalData } = useContext(AuthContext)!;
  const [peopleData, setPeopleData] = useState<any>({name: "独自", num: 0});

  const updateItemBack = (newItem: any) => {
     newItem.ValueJson = JSON.stringify(newItem.ValueEnum);
     console.log("makeRequest:", newItem);
     makeRequest('put', 'condition', newItem, {}, (response) => {
         console.log("updateItems response:", response);
        if (fcallback) {
            fcallback(newItem);
        }
     });
  };
  
  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Togerther With who";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);



  const jumpCateEdit = () => {
    if (!userInfo.PeopleCategory) {
        return;
    }
    console.log("jumpCateEdit:", userInfo.PeopleCategory);
    setFormStack([...formStack, {type: "category", uuid: userInfo.PeopleCategory.ID, data: userInfo.PeopleCategory, 
        fcallback: (newData: any) => {
            let newUserInfo = userInfo;
            console.log("FreeTime newData:", newData);
            newUserInfo.ParaInt = 1;
            getItem(newData.ID, cm.TYPE_CATE_ELEM, newUserInfo, (newElems: any) => {
                newUserInfo.PeopleElems = newElems;
                newUserInfo.ParaInt = 0;
                setUserInfo(newUserInfo);
                if(fcallback) {
                    fcallback(newElems);
                }
            });
        }
    }]);
  }

  const handleToggle = (currentItem: any, idx: number) => {
    let newItems = [...items];
    let current = currentItem.Condition.ValueEnum.CurrentValue;
    console.log("currentItem:", current);
    if (current.length === 0) {
        current = [0];
    }else {
        current = [];
    }
    console.log("update currentItem:", current);
    newItems[idx].Condition.ValueEnum.CurrentValue = current;
    updateItemBack(newItems[idx].Condition);
    setItems(newItems);
  }

  return (
    <Box>
    <Typography 
        variant="h6" 
        color="primary"
        onClick={() => jumpCateEdit()}
        style={{ cursor: 'pointer' }}
        gutterBottom>
        Edit People interaction <GroupIcon />
    </Typography>
    {/* add divider */}
    <Divider 
        sx={{ marginTop: 2, marginBottom: 3 }}
    />
    <Grid container spacing={1}>
        {items.map((item: any, idx: number) => {
            return (
                <Grid item key={idx}>
                    <Button
                        fullWidth
                        variant={item.Condition.ValueEnum.CurrentValue.length > 0 ? 'contained' : 'outlined'}
                        onClick={() => handleToggle(item, idx)}
                    >
                        {item.Name}
                    </Button>
                </Grid>
                )
            }
        )}
    </Grid>
    </Box>
        
  );
};

export default TPeople;

