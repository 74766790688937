import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Login from './Login';
import Register from './Register';
import App from './App';
import { AuthProvider, AuthContext } from './AuthContext';

const theme = createTheme({
  components: { 
    MuiFormControl: { 
      styleOverrides: { 
        root: { 
          margin: '0.8em 0',
        },
      } 
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

const Root: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/app" element={<ProtectedRoute><App /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { jwt } = React.useContext(AuthContext)!;
  return jwt ? <>{children}</> : <Navigate to="/" />;
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Root />
  </ThemeProvider>,
  document.getElementById('root')
);
