import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/lenum_schema.json';
import uischema from './json/lenum_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  allEnums?: any[];
  fcallback?: any;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, allEnums, fcallback }) => {
  const [data, setData] = useState<any>(editComp);
  const [customUiSchema, setCustomUiSchema] = useState<any>(uischema);

  const debouncedSave = useCallback(
    _.debounce((newData) => {
        if(fcallback){
            fcallback(newData);
        }
    }, 100),
    []
  );

  useEffect(() => {
    let newUiSchema = {...customUiSchema};
    if (allEnums && allEnums.length > 0) {
		setCustomUiSchema((prevUiSchema:any) => {
		  const newUiSchema = {
			...prevUiSchema,
			elements: prevUiSchema.elements.map((element:any) => ({
			  ...element,
			  options: {
				...element.options,
				enumOptions: allEnums.map((item:any) => item.Note), // 更新 enumOptions
			  },
			})),
		  };

		  return newUiSchema; // 返回新的 uiSchema
		});
    }
  }, [allEnums]);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

  const removeDuplicates = (array: number[]): number[] => {
	  const set = new Set(array);
	  const uniqueArray: number[] = [];
	  set.forEach((value) => uniqueArray.push(value)); // 手动遍历 Set
	  return uniqueArray;
  };

 
  const handleDataChange = (newData: any) => {
    console.log("enumArray:", newData);
    if (newData.EnumArray && Array.isArray(newData.EnumArray)) {
    	newData.EnumArray = removeDuplicates(newData.EnumArray);
  	}

    if(newData !== data) {
        debouncedSave(newData);
        setData(newData);
    }
  }

  return (
        <JsonForms
          schema={schema}
          uischema={customUiSchema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ECondition;

