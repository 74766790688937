import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';
import * as cm from './components/utils/common';

interface AuthContextType {
  jwt: Object | null;
  setJwt: (jwt: Object | null) => void;
  userInfo: any;
  setUserInfo: (userInfo: any) => void;
  formStack: any[];
  setFormStack: React.Dispatch<React.SetStateAction<any[]>>;
  globalData: any;
  setGlobalData: (globalData: any) => void;
}

interface ResponseData {
  code: number;
  message?: string;
  data: any;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [jwt, setJwt] = useState<Object | null>(() => {
        const jwtString = localStorage.getItem('jwt');
        return jwtString ? JSON.parse(jwtString) : null;
    });
  const [userInfo, setUserInfo] = useState<any>(()=>{
      const userInfoString = localStorage.getItem('userInfo'); 
      return userInfoString ? JSON.parse(userInfoString) : null;
    });
  const [formStack, setFormStack] = useState<any[]>([]);
  const [globalData, setGlobalData] = useState<any>();

  useEffect(() => {
    if (jwt) {
      localStorage.setItem('jwt', JSON.stringify(jwt));
    } else {
      localStorage.removeItem('jwt');
    }
  }, [jwt]);

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } else {
      localStorage.removeItem('userInfo');
    }
  }, [userInfo]);

  useEffect(() => {
    /*
    if (formStack) {
      localStorage.setItem('formStack', JSON.stringify(formStack));
    } else {
      localStorage.removeItem('formStack');
    }
    */
  }, [formStack]);

  return (
    <AuthContext.Provider value={{ jwt, setJwt, userInfo, setUserInfo, formStack, setFormStack, globalData, setGlobalData}}>
      {children}
    </AuthContext.Provider>
  );
};

export const makeRequest = async (
  method: Method,
  rawUrl: string,
  data: any,
  config: AxiosRequestConfig,
  onSuccess: (data: ResponseData) => void
) => {
  var newConfig = config;
  var url= rawUrl;
  if (method === 'get' && data) {
    method = 'post';
    url = rawUrl+"get"
  }
  if (rawUrl !== '/auth')  {
      url = '/api/v1/' + url;
      var addToken = true;
      if (config.headers) {
          if (config.headers['token']) {
              addToken = false;
          }
      }
      if (addToken) {
        const jwt_local_str = localStorage.getItem('jwt')
        var jwt_obj = jwt_local_str ? JSON.parse(jwt_local_str) : null;
        const token = jwt_obj ? jwt_obj['Token'] : null;
        //console.log('token: ' + token, 'jwt_local_str: ' + jwt_local_str, 'type: ', typeof(jwt_obj));

          newConfig = {
            ...config,
            headers: {
                ...config.headers,
                token
            }
          };
      }
  }

    
  try {
    const response = await axios({ method, url, data, ...newConfig});
    const responseData: ResponseData = response.data;

    //console.log('responseData: ', responseData);
    if (responseData.code === 200) {
      if (onSuccess){
        onSuccess(responseData);
      }
    } else {
      //onError(`Request failed. Code: ${responseData.code} Message: ${responseData.message}`);
      //console.log(`Request failed. Code: ${responseData.code} Message: ${responseData.message}`);
      //onSuccess(responseData);
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.message) {
        console.log('axios err: ', err);
      //onError(`Request failed: ${err.message}`);
    } else {
      //onError('Request failed: Unknown error');
      console.log('err: ', err, 'url', url, 'data', data, 'config', newConfig);
    }
  }
};

export const addItem = (selectedItem:any,  selectedType:any, searchQuery:any,  userInfo:any, callback:any) => {
      console.log("addItem: ", selectedType, "searchQuery: ", searchQuery, "userInfo: ", userInfo.ID);
      //todo add code here
      var newListId = selectedItem;
      if (selectedItem === "00000000-0000-0000-0000-000000000000" || selectedItem === "") {
          newListId = userInfo.DefaultList;
      }
      let cm_sel = cm.baseType[selectedType]

      let paraInt = 0;
      if (userInfo.paraInt) {
          paraInt = userInfo.paraInt
      }
      let paraStr = [];
      if (userInfo.paraStr) {
          paraStr = userInfo.paraStr
      }
      console.log("cm_sel: ", cm_sel, "paraInt: ", paraInt, "paraStr: ", paraStr);
      if (selectedType === cm.TYPE_TODO) {
          makeRequest('post', cm_sel.url, {
              user_id: userInfo.ID,
              ListId: newListId,
              TodoType: paraInt,
              name: searchQuery
              }, {}, (data) => {
              const newItem = JSON.parse(data.data);
              if (callback) {
                callback(newItem);
              }
          });
      } else if (selectedType === cm.TYPE_LOGIC_ELEM) {
        makeRequest('post', cm_sel.url, {
            UserId: userInfo.ID,
            Type: 1,
            Name: searchQuery
            }, {}, (data) => {
                const newItem = JSON.parse(data.data);
                if (callback) {
                  callback(newItem);
                }
        });
      } else {
        makeRequest('post', cm_sel.url, {
            UserId: userInfo.ID,
            ListId: newListId,
            Name: searchQuery
            }, {}, (data) => {
                const newItem = JSON.parse(data.data);
                if (callback) {
                  callback(newItem);
                }
        });
      }
}

export const delItem = (itemId:any,  itemType:any,  userInfo:any, callback:any) => {
    let cm_sel = cm.baseType[itemType];
      let paraInt = 1;
      if (userInfo.paraInt) {
          paraInt = userInfo.paraInt
      }
      let paraStr = [];
      if (userInfo.paraStr) {
          paraStr = userInfo.paraStr
      }
    let query = {ID: itemId, UserId: userInfo.ID, ApiMethod: paraInt, ApiStr: paraStr};
    makeRequest('delete', cm_sel.url, query, {}, (response) => {
        if (response) {
            if (callback) {
                callback(JSON.parse(response.data));
            }
        }
    });
}


export const getItem = (itemId:any,  itemType:any,  paraUser:any, callback:any) => {
    let cm_sel = cm.baseType[itemType];
    let query = {ID: itemId, UserId: paraUser.ID};
    console.log("query", query, " type", itemType, " cm_sel", cm_sel)
    if (itemType === cm.TYPE_TODO) {
        makeRequest('get', cm_sel.url, query, {}, (response) => {
            if (response) {
                if (callback) {
                    callback(JSON.parse(response.data));
                }
            }
        });
    }else if (itemType === cm.TYPE_CATE_ELEM) {
        let itemQuery = {ID: itemId, UserId: paraUser.ID, ApiPara: itemId, ApiMethod: paraUser.ParaInt};
        console.log("itemQuery", itemQuery, " type", itemType, " cm_sel", cm_sel)
        makeRequest('get', cm_sel.url, itemQuery, {}, (response) => {
            if (response) {
                //response.data is array of objects, just get the first one
                let itemData = JSON.parse(response.data);
                if (callback) {
                    console.log("getItem response:", itemData)
                    if (itemData){
                        if (itemData.length === 1) {
                            callback(itemData[0]);
                        }else{
                            callback(itemData);
                        }
                    }
                }
            }
        });
    }else{
        makeRequest('get', cm_sel.url, query, {}, (response) => {
            if (response) {
                if (callback) {
                    callback(JSON.parse(response.data));
                }
            }
        });
    }
}



