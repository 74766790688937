import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import VAutoComp from './VAutoComp';

import * as cm from '../utils/common';

const VAutoCompControl: React.FC<ControlProps> = ({ data, handleChange, path, uischema }) => {
    let autoType = '';
    let paraStr = [];
    let paraInt = 0;
    let oneMapName = 'Todo';
    let enableEdit = false;
    let compSetting = cm.ACOMP_ONLY_ONE;
    if (uischema.options) {
      autoType = uischema.options.autoType;
      paraStr = uischema.options.paraStr;
      paraInt = uischema.options.paraInt;
      oneMapName = uischema.options.oneMapName;
      enableEdit = uischema.options.enableEdit;
      if (uischema.options.order && uischema.options.order === 1){
        compSetting = cm.ACOMP_ORDER
      }
    }
    return(
      <VAutoComp 
        autoType={autoType}
        currentString={data}
        paraStr={paraStr}
        paraInt={paraInt}
        oneMapName={oneMapName}
        enableEdit={enableEdit}
        compSettingRaw={compSetting}
        setCurrentString={(value: string) => handleChange(path, value)}
        setParaInt={(value: number) => handleChange(path, value)}
      />
      );
};


export default withJsonFormsControlProps(VAutoCompControl);
