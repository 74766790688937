import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/lrange_schema.json';
import uischema from './json/lrange_uischema.json';
import time_uischema from './json/lrange_time_uischema.json';
import opera_ui from './json/lrange_opera_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
  showOperator?: boolean;
  isTime?: boolean;
}

const ELRange: React.FC<EditCondPropos> = ({ editComp, fcallback, showOperator, isTime }) => {
  const [data, setData] = useState<any>(editComp);

  const debouncedSave = useCallback(
    _.debounce((newData) => {
        if(newData !== data && fcallback) {
            fcallback(newData);
        }
    }, 100),
    []
  );


  useEffect(() => {
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

  const getUISchema = () => {
      let newUISchema = JSON.parse(JSON.stringify(uischema));
      if (isTime){
          newUISchema = time_uischema;
      }
      if (showOperator){
          newUISchema.elements.push(opera_ui);
      }
      return newUISchema;
  }

  return (
        <JsonForms
          schema={schema}
          uischema={getUISchema()}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ELRange;

