import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/todo_schema.json';
import uischema from './json/todo_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext,  makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface EditTodoPropos {
  todo: any;
  fcallback?: any;
}


const EditTodo: React.FC<EditTodoPropos> = ({ todo, fcallback }) => {
  const [data, setData] = useState<any>(todo);
  const { globalData, setGlobalData } = useContext(AuthContext)!;

  //print call stack
  //console.log(new Error().stack);
  const debouncedSave = useCallback(
    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'todo', updatedData, {}, (response) => {
        if (response.code !== 200) {
            console.log("response:", response);
            const newTodo = JSON.parse(response.data);
            setData(newTodo);
        }
      });
    }, 500),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Todo";
      setGlobalData(newGlobalData);

      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
        debouncedSave.flush();
      }

  }, []);

/*
  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, [debouncedSave]);
*/

 
  const handleDataChange = (newData: any) => {
    if (data !== newData) {
        const updatedData = compareAndUpdateObjects(data, newData);
        console.log("EditTodo newData:", updatedData);
        let saveData = {...newData};
        saveData.duration.DurationJson = JSON.stringify(saveData.duration.PureDurations);
        let todoGroup = [...saveData.TodoGroup];
        for (let i = 0; i < todoGroup.length; i++) {
            if (todoGroup[i].OrderTodo !== undefined){
                todoGroup[i].OrderTodo = [];
                todoGroup[i].UnOrderTodo = [];
            }
        }
        saveData.TodoGroupArrayJson = JSON.stringify(todoGroup);
        saveData.TodoGroup = [];
        debouncedSave(saveData);
    }
  }

  return (
    <div>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
    </div>
  );
};

export default EditTodo;

