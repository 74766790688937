import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/category_schema.json';
import uischema from './json/category_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box } from '@mui/material';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

const ECondition: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  //防止 debouncedSave 在组件的生命周期内稳定引用，并且不会因为 handleDataChange 被多次触发而重新创建
  const debouncedSave = useCallback(

    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
      makeRequest('put', 'category', newData, {}, (response) => {
        let respData = JSON.parse(response.data);
        console.log("ECategory:", respData);
        if (response.code !== 200) {
            console.log("response:", response);
            setData(respData);
        }
        if(fcallback){
            fcallback(respData);
        }

        //setData(newData);
        //const getConds = JSON.parse(response.data);
      });
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Category";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);

 
  const handleDataChange = (newData: any) => {
    console.log("cate newData:", newData);
    if(newData !== data) {
        debouncedSave(newData);
    }
  }

    //<Box display="flex" justifyContent="bottom" alignItems="flex-end">
  return (
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={customRenderers}
          cells={materialCells}
          onChange={({ errors, data }) => handleDataChange(data)}
        />
  );
};

export default ECondition;

