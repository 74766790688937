import dayjs, { Dayjs } from 'dayjs';

export function compareAndUpdateObjects(oldObj, newObj) {
  const result = newObj;

  for (const key in oldObj) {
    if (oldObj.hasOwnProperty(key)) {
      if (typeof oldObj[key] === 'object' && oldObj[key] !== null && newObj[key] !== undefined) {
        // Recursively compare nested objects
        result[key] = compareAndUpdateObjects(oldObj[key], newObj[key]);
      } else if (newObj[key] === undefined && typeof oldObj[key] === 'string') {
        // If new object property is undefined and old property is a string, set it to an empty string
        result[key] = '';
      } else if (oldObj[key] !== newObj[key]) {
        // If the values are different, include the new value in the result
        result[key] = newObj[key];
      }
    }
  }

  // Include keys that are only in the new object
  for (const key in newObj) {
    if (newObj.hasOwnProperty(key) && !oldObj.hasOwnProperty(key)) {
      result[key] = newObj[key];
    }
  }

  return result;
}

// todoStatus.js
export const TODO_STATUS_READY = 0;
export const TODO_STATUS_RUNNING = 1;
export const TODO_STATUS_RUN_PREPARE = 2;
export const TODO_STATUS_RUN_SUB = 3;
export const TODO_STATUS_RUN_FINISH = 4;
export const TODO_STATUS_RUN_RECURSIVE = 5;

// User API's Value
export const TODO_STATUS_PRE_RUN = 6;
export const TODO_STATUS_PAUSE = 7;
export const TODO_STATUS_PRE_ONE_FINISH = 8;
export const TODO_STATUS_DONE = 9;
export const TODO_STATUS_MAX_STATUS = 10;

// auto complete enum
export const ACOMP_NONE = 0;
export const ACOMP_ONLY_ONE = 1;
export const ACOMP_ORDER = 2;

export const td_status_str = [
  'Ready',
  'Running',
  'Run Prepare',
  'Run Sub',
  'Run Finish',
  'Run Recursive',
  'Pre Run',
  'Pause',
  'Pre One Finish',
  'Done',
  'Max Status',
];

export const ACT_NONE = 0;
export const ACT_USER = 1;
export const ACT_LIST = 2;
export const ACT_TODO = 3;
export const ACT_TODO_OPERA = 4;
export const ACT_DURATION = 5;
export const ACT_LOGIC = 6;
export const ACT_PROPOS = 7;
export const ACT_PROPOS_RANGE = 8;
export const ACT_PROPOS_ENUM = 9;
export const ACT_PROPOS_GPS = 10;
export const ACT_PROPOS_CATEGORY = 11;
export const ACT_PROPOS_FREE_TIME = 12;
export const ACT_PROPOS_COMPOSE = 13;
export const ACT_CONDITION = 14;
export const ACT_CONDITION_UPDATE_DURATION = 15;
export const ACT_CONDITIION_AVAIL_DURATION = 16;
export const ACT_CONDITION_RANGE = 17;
export const ACT_CONDITION_ENUM = 18;
export const ACT_CONDITION_GPS = 19;
export const ACT_CONDITION_CATEGORY = 20;
export const ACT_CONDITION_FREE_TIME = 21;
export const ACT_CONDITION_COMPOSE = 22;
export const ACT_CATEGORY = 23;
export const ACT_CATEGORY_ELEM = 24;
export const ACT_TODO_CATEGORY = 25;
export const ACT_CLIENT_DEVICE = 26;
export const ACT_MAX = 27;

export const TYPE_TODO = 0;
export const TYPE_LOGIC = 1;
export const TYPE_LOGIC_ELEM = 2;
export const TYPE_RESOURCES = 3;
export const TYPE_CATEGORY = 4;
export const TYPE_CATE_ELEM = 5;
export const TYPE_VALID_TIME = 6;
export const TYPE_VALID_GROUP = 7;
export const TYPE_TRIGGER = 8;

export const baseType = [
  {name: 'Todo', value: TYPE_TODO, url: 'todo', act: ACT_TODO},
  {name: 'Logic', value: TYPE_LOGIC, url: 'logic', act: ACT_LOGIC},
  {name: 'Logic Elem', value: TYPE_LOGIC_ELEM, url: 'propos', act: ACT_PROPOS},
  {name: 'Resources', value: TYPE_RESOURCES, url: 'condition', act: ACT_CONDITION},
  {name: 'Category', value: TYPE_CATEGORY, url: 'category', act: ACT_CATEGORY},
  {name: 'Category Elem', value: TYPE_CATE_ELEM, url: 'elem', act: ACT_CATEGORY_ELEM},
  {name: 'Valid Time', value: TYPE_VALID_TIME, url: 'condUpdate', act: ACT_CONDITION_UPDATE_DURATION},
  {name: 'Valid Time Group', value: TYPE_VALID_GROUP, url: 'condAvail', act: ACT_CONDITIION_AVAIL_DURATION},
  {name: 'Trigger', value: TYPE_TRIGGER, url: 'todoOpera', act: ACT_TODO_OPERA},
]

export const LOGIC_TYPE_NONE = 0;
export const LOGIC_TYPE_RANGE = 1;
export const LOGIC_TYPE_ENUM = 2;
export const LOGIC_TYPE_GPS = 3;
export const LOGIC_TYPE_CATEGORY = 4;
export const LOGIC_TYPE_FREE_TIME = 5;
export const LOGIC_TYPE_COMPOSE = 6;
export const LOGIC_TYPE_MAX = 7;


// DurationType 常量
const DURATION_TYPE_NONE = 0;
const DURATION_TYPE_COUNT = 1;
const DURATION_TYPE_FAST = 2;
const DURATION_TYPE_DATE = 3;

const OPERA_NONE = 0;
const OPERA_TODO = 1;
const OPERA_CONDITION = 2;
const OPERA_URL = 3;
const OPERA_URL_TODO_NOTE = 4;
const OPERA_URL_TODO_NOTE_RSS = 5;
const OPERA_CATE_RSS = 6;
const OPERA_ELEM_RSS = 7;


function timeString(todo, timeCount, pauseCount) {
  if (todo.Status === TODO_STATUS_PAUSE) {
    let str = "00:00";
    if (pauseCount < 60 * 5) {
      return "WR_PAUSED";
    }
    const days = Math.floor(pauseCount / (24 * 3600));
    const hours = Math.floor((pauseCount - days * (24 * 3600)) / 3600);
    const minutes = Math.floor((pauseCount - days * (24 * 3600) - hours * 3600) / 60);
    if (days > 0) {
      str = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } else {
      str = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    return `WR_PAUSED${str ? `(${str})` : ''}`;
  } else {
    let isNeg = false;
    let newCount = timeCount;
    if (timeCount < 0) {
      isNeg = true;
      newCount = -timeCount;
    }

    if (timeCount === 0) {
      return "00:00";
    }
    const days = Math.floor(newCount / (24 * 3600));
    const hours = Math.floor((newCount - days * (24 * 3600)) / 3600);
    const minutes = Math.floor((newCount - days * (24 * 3600) - hours * 3600) / 60);
    
    if (days > 0) {
      return `${isNeg ? '-' : ''}${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    return `${isNeg ? '-' : ''}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}

function getMenuName(todo, counter, pause_counter) {
  const status = todo.Status ?? TODO_STATUS_DONE;
  let menuName = "";

  if (status === TODO_STATUS_RUNNING || status === TODO_STATUS_PAUSE) {
    if (todo.duration && todo.duration.showSecond && todo.duration.showSecond !== 0) {
      menuName = new Date().getSeconds().toString();
    } else {
      menuName = timeString(todo, counter, pause_counter);
    }
  } else if (status === TODO_STATUS_RUN_PREPARE) {
    menuName = "Prepare";
  } else if (status === TODO_STATUS_RUN_SUB) {
    menuName = "Sub";
  } else if (status === TODO_STATUS_RUN_FINISH) {
    menuName = "Done";
  } else if (status === TODO_STATUS_RUN_RECURSIVE) {
    menuName = "Recursive";
  } else {
    menuName = "";
  }

  return menuName;
}

function pauseRunTime(todo, now) {
  if (todo.Record) {
    let alreadyCounter = 0;
    alreadyCounter = Math.floor((now - new Date(todo.Record.LastTime)) / 1000);
    return alreadyCounter;
  }
  return 0;
}


function runTime(todo, now) {
  if (todo.Record) {
    const unEndTime = new Date('2019/02/22 23:10:06');
    let alreadyCounter = 0;

    if (new Date(todo.Record.OneLastStart) > unEndTime) {
      alreadyCounter = (todo.Record.oneAlreadyDuration ?? 0) / 1_000_000_000;
    } else {
      return (60 * 8 + 8);
    }

    if (todo.Status === TODO_STATUS_RUNNING) {
      const inc = -Math.floor((new Date(todo.Record.OneLastStart) - now) / 1000);
      return alreadyCounter + inc;
    } else {
      return alreadyCounter;
    }
  }
  return 0;
}

function formatDuration(duration) {
  const days = Math.floor(duration / (24 * 3600));
  const hours = Math.floor((duration % (24 * 3600)) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
}


function AlreadyRun(todo) {
  if (todo.Record) {
    const totalTime = todo.Record.OnWallTodoTime;
    if (totalTime) {
      const totalInterval = totalTime / 1_000_000_000;
      if (totalInterval > 0) {
        return formatDuration(totalInterval);
      }
    }
  }
  return "";
}


const reminderDate = (date, isDay) => {
  const now = new Date();
  const timeIntervalSinceNow = (date - now) / 1000; // Difference in seconds

  let options;

  if (!isDay) {
    // Format as MM/dd
    options = { month: '2-digit', day: '2-digit' };
  } else {
    if (timeIntervalSinceNow >= 24 * 3600 || timeIntervalSinceNow < 0) {
      // If more than a day ahead or in the past, format as MM/dd HH:mm
      options = { 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit' 
      };
    } else {
      // Otherwise, format as HH:mm
      options = { hour: '2-digit', minute: '2-digit' };
    }
  }

  const formatter = new Intl.DateTimeFormat(undefined, options);
  return formatter.format(date);
};

function getReminderText(todo) {
  let retStr = "";
  const alreadyStr = AlreadyRun(todo);

  if (todo.duration) {
    const duration = todo.duration;
    const dtype = duration.DurationType ?? 0;

  	//console.log(duration.NextDate);
    if (duration.TotalCount && duration.TotalCount !== 0 && dtype === DURATION_TYPE_COUNT) {
      retStr += `(${duration.AlreadyCount ?? 0}/${duration.TotalCount ?? 1})`;
      if (alreadyStr !== "") {
        retStr += ` [${alreadyStr}]`;
      }
    } else {
      if (duration.NextDate) { // firstReminder
        retStr = reminderDate(dayjs(duration.NextDate).utc(), true);
      }
      if (duration.AlreadyCount && duration.AlreadyCount !== 0) {
        retStr += ` (${duration.AlreadyCount ?? 0}`;
        if (alreadyStr !== "") {
          retStr += `|${alreadyStr}`;
        }
        retStr += ")";
      } else {
        if (alreadyStr !== "") {
          retStr += ` [${alreadyStr}]`;
        }
      }
      if (retStr === "") {
        retStr = "W_EDIT"; // 忽略 localized
      }
    }
  }

  return retStr;
}


export const getGroupTodos = (todo) => {
    let newTodos = [];
    if (todo.TodoGroup){
        //add todo.TodoGroup[0].OrderTodos
        for (let i = 0; i < todo.TodoGroup.length; i++) {
            let status = todo.Status

            if (i === 0 ) {
                if (status!== TODO_STATUS_RUN_PREPARE)
                    continue;
            }else if (i=== 1) {
                newTodos.push(todo);
                if (status!== TODO_STATUS_RUN_SUB)
                    continue;
            }else if (i=== 2) {
                if (status!== TODO_STATUS_RUN_FINISH)
                    continue;
            }
            if (todo.TodoGroup[i].OrderTodo) {
                for (let j = 0; j < todo.TodoGroup[i].OrderTodo.length; j++) {
                    newTodos.push(todo.TodoGroup[i].OrderTodo[j]);
                }
            }
            if (todo.TodoGroup[i].UnorderTodo) {
                for (let j = 0; j < todo.TodoGroup[i].UnorderTodo.length; j++) {
                    newTodos.push(todo.TodoGroup[i].UnorderTodo[j]);
                }
            }
        }
    }
    return newTodos;
}


export const getTodoMenu= (todo) => {
    const now = new Date();
    const reminderText = getReminderText(todo);
    const menuName = getMenuName(todo, runTime(todo, now), pauseRunTime(todo, now));
    if (menuName !== "") {
        return menuName;
    }else{
        return `${reminderText}`;
    }
}

export const updateAllEnumValue = (initData: any) => {
      let CurrentValue = initData.CurrentValue || [];
      if (initData.AllEnumValue ) {
          for (let i = 0; i < initData.AllEnumValue.length; i++) {
              let item = initData.AllEnumValue[i];
              if(CurrentValue.includes(i)){
                  initData.AllEnumValue[i].Select = true;
                  initData.AllEnumValue[i].Name = initData.AllEnumValue[i].Note + " [Selected]"
              }else{
                  initData.AllEnumValue[i].Select = false;
                  initData.AllEnumValue[i].Name = initData.AllEnumValue[i].Note
              }
          }
      }
      return initData;
  }

export const reverseAllEnumValue = (initData: any) => {
    let CurrentValue = []
    if (initData.AllEnumValue) {
        for (let i = 0; i < initData.AllEnumValue.length; i++) {
            let item = initData.AllEnumValue[i];
            if(item.Select){
                CurrentValue.push(i);
            }
        }
        initData.CurrentValue = CurrentValue
    }
    return initData;
 }

/*
 {
    "Todos": [],
    "EffectConds": [],
    "Elems": [
        {
            "ID": "1db0563c-da23-49fe-a2b5-204ade557d6f",
            "ApiPara": "",
            "UpdatedAt": "2024-10-31T06:30:13Z"
        }
    ],
    "CanRun": false,
    "RTodoIds": [],
    "RCondIds": [],
    "RElemIds": [
        "1db0563c-da23-49fe-a2b5-204ade557d6f"
    ],
    "RTodoDone": 0,
    "RootId": "00000000-0000-0000-0000-000000000000"
}
 */

export const showCondValue = (todo) => {
    if (todo.Recursive) {
        let recursive = todo.Recursive;
        if (recursive.Elems && recursive.Elems.length > 0 && todo.Status === TODO_STATUS_RUNNING) {
            return true;
        }
        if (recursive.EffectConds && recursive.EffectConds.length > 0 && todo.Status === TODO_STATUS_RUNNING) {
            return true;
        }
    }
    return false;
}

