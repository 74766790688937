import React, { useState, useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { JsonForms } from '@jsonforms/react';
import schema from './json/condition_schema.json';
import uischema from './json/condition_uischema.json';
import { materialCells } from '@jsonforms/material-renderers';
import { AuthContext, makeRequest } from '../AuthContext';
import { customRenderers } from './customRenderers';
import { compareAndUpdateObjects } from './utils/common';
import { Box, Paper, Card, CardContent, CardHeader, Typography } from '@mui/material';

import NoteContent from './NoteContent';

import ELRangeV from './ELRangeV';
import ELEnumV  from './ELEnumV'
import ELGPSV from './ELGPSV'
import ELComposeV from './ELComposeV'
import ECondition from './ECondition';

import * as cm from './utils/common.js';

interface EditCondPropos {
  editComp: any;
  fcallback?: any;
}

/*
 {
    "Todos": [],
    "EffectConds": [],
    "Elems": [
        {
            "ID": "1db0563c-da23-49fe-a2b5-204ade557d6f",
            "ApiPara": "",
            "UpdatedAt": "2024-10-31T06:30:13Z"
        }
    ],
    "CanRun": false,
    "RTodoIds": [],
    "RCondIds": [],
    "RElemIds": [
        "1db0563c-da23-49fe-a2b5-204ade557d6f"
    ],
    "RTodoDone": 0,
    "RootId": "00000000-0000-0000-0000-000000000000"
}
 */

const ERecursive: React.FC<EditCondPropos> = ({ editComp, fcallback }) => {
  const [data, setData] = useState<any>(editComp);

  const { globalData, setGlobalData } = useContext(AuthContext)!;

  //防止 debouncedSave 在组件的生命周期内稳定引用，并且不会因为 handleDataChange 被多次触发而重新创建
  const debouncedSave = useCallback(

    _.debounce((newData) => {
      const updatedData = compareAndUpdateObjects(data, newData);
    }, 1000),
    []
  );


  useEffect(() => {
      let newGlobalData =  {...globalData};
      let oldTitle = newGlobalData.Title;
      newGlobalData.Title = "Recursive Value";
      setGlobalData(newGlobalData);
      return () => {
          let newGlobalData =  {...globalData};
          newGlobalData.Title = oldTitle;
          setGlobalData(newGlobalData);
      };
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.flush(); // 立即执行 debouncedSave 的回调
    };
  }, [debouncedSave]);

 
  const handleMainChange = (newData: any) => {
    if(newData !== data) {
        debouncedSave(newData);
    }
  }


/*
                <Typography variant="h6" gutterBottom>
                    {elem.Name}
                </Typography>
                */

return (
    <Box>
    {editComp.Elems.map((elem: any, index: number) => (
        <Card key={index} sx={{ padding: 0, boxShadow: 'none', borderBottom: '1px solid rgba(0,0,0,0.12)',  width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
                <NoteContent content={elem.Note} />
            </CardContent>
        </Card>
    ))
    }
    {editComp.EffectConds.map((elem: any, index: number) => (
        <Card key={index} sx={{ padding: 0, boxShadow: 'none', borderBottom: '1px solid rgba(0,0,0,0.12)',  width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
                <Typography variant="h6" gutterBottom>
                    {elem.Name}
                </Typography>
                <ECondition editComp={elem} fcallback={handleMainChange} onlyShowValue={true} />
            </CardContent>
        </Card>
    ))
    }
    </Box>
)
};

export default ERecursive;

