import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteContent from './NoteContent'; // 引入新组件

import * as cm from './utils/common';

interface TodoItemProps {
  item: any; // item的类型为any
  onMenuClick: (menuName: string, item: any) => void;
  subMenu: any[];
}

const CompactCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  //boxShadow: theme.shadows[1], // 调整阴影大小
  '.MuiCardContent-root': {
    padding: theme.spacing(1), // 缩小内边距
    '&:last-child': {
      paddingBottom: theme.spacing(1), // 去掉多余的底部内边距
    },
  },
}));

const TodoItem: React.FC<TodoItemProps> = ({ item, onMenuClick, subMenu }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [subHeader, setSubHeader] = useState<string>('');
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const oneClick = () => {
	if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
        onMenuClick('Edit', item)
    } else {
      const timeout = setTimeout(() => {
        onMenuClick('Start', item);
        setClickTimeout(null);
      }, 300);
      setClickTimeout(timeout);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (menuName: string) => {
    setAnchorEl(null);
    onMenuClick(menuName, item);
  };

  const headerContent = (
    <Typography 
        variant="h6" 
        //onClick={() => onMenuClick('Start', item)}
        onClick={oneClick}
        style={{ cursor: 'pointer' }}>
      {item.Name}
    </Typography>
  );

  useEffect(() => {
    setSubHeader(cm.getTodoMenu(item));
    if (item.Status === cm.TODO_STATUS_RUNNING){
        //call setSubHeader to update the subHeader every 60 seconds
        const interval = setInterval(() => {
            setSubHeader(cm.getTodoMenu(item));
        }, 60000);
        return () => clearInterval(interval);
    }
  }, [item]);


  const subHeaderContent = (
    <Typography 
        variant="body2"
        color="textSecondary"
        //style={{ cursor: 'pointer' }}
        //onClick={() => onMenuClick('Edit', item)}
    >
        {subHeader}
    </Typography>
  );

  return (
    <Card sx={{ border: '0.5px solid #ccc', borderRadius: '8px', boxShadow: 'none', marginBottom: 0 }}>
      <CardHeader
        title={headerContent}
        subheader={subHeaderContent}
        action={
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        }
      />
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
      {
          subMenu.map((menu) => (
            <MenuItem
              key={menu.name}
              onClick={() => handleSubMenuClick(menu.name)}
            >
              {menu.name}
            </MenuItem>
          ))
      }
      </Menu>

      {item.Note && (
        <CardContent
          onClick={() => setShowDetail(!showDetail)}
          sx={{
            maxHeight: '70vh',
            overflow: 'auto'
          }}
        >
          <NoteContent content={item.Note} />
        </CardContent>
      )}

      {showDetail && item.HiddenValue && (
        <CardActions>
        {
          //<NoteContent content={item.HiddenValue} />
        }
          <Typography variant="body2" color="textSecondary">
            {item.HiddenValue}
          </Typography>
        </CardActions>
      )}
      
    </Card>
  );
};

export default TodoItem;

